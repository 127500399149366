<w-block-ui [blocked]="blocked" [title]="'processing' | translate" [text]="'please_wait' | translate"></w-block-ui>

<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <w-page-header title="{{'ipportalDefault.mediation.pageTitle' | translate}}" [disableUppercase]="true"
    [showPortfolio]="false">
  </w-page-header>
  <w-step>
    <p facet-step-info *ngIf="collaboration !== 'NONE'" [innerHTML]="'mcstForm.mediation.pageDesc' | translate"></p>
    <p facet-step-info *ngIf="collaboration === 'NONE'" [innerHTML]="'ipportalDefault.mediation.pageDesc' | translate">
  </w-step>

  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-text label="{{'requestingPartyName' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="nameComplainant">
          <f-facet name="error" *ngIf="submitted && f.nameComplainant.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
            <f-facet name="error" *ngIf="submitted && f.nameComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="addressComplainant">
          <f-facet name="error" *ngIf="submitted && f.addressComplainant.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.addressComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true" formControlName="telephoneComplainant">
          <f-facet name="error" *ngIf="submitted && f.telephoneComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="emailComplainant" [type]="'email'">
          <f-facet name="error" *ngIf="submitted && f.emailComplainant.errors?.required">{{'validationWarning' |
            translate }}
          </f-facet>
          <f-facet name="error" *ngIf="f.emailComplainant.errors?.email">{{'validationEmail' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>

        <w-input-text label="{{'representedBy' | translate}}" [labelOnTop]="true"
          formControlName="representedByComplainant">
          <f-facet name="error" *ngIf="submitted && f.representedByComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true"
          formControlName="addressRepresentativeComplainant">
          <f-facet name="error" *ngIf="submitted && f.addressRepresentativeComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true"
          formControlName="telephoneRepresentativeComplainant">
          <f-facet name="error" *ngIf="submitted && f.telephoneRepresentativeComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true"
          formControlName="emailRepresentativeComplainant" [type]="'email'">
          <f-facet name="error" *ngIf="f.emailRepresentativeComplainant.errors?.email">{{'validationEmail' | translate
            }}
          </f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailRepresentativeComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
      <w-slot>
        <w-input-text label="{{'respondingByName' | translate}}" [labelOnTop]="true" formControlName="nameRespondent"
          [required]="true">
          <f-facet name="error" *ngIf="submitted && f.nameRespondent.errors?.required">{{'validationWarning' | translate
            }}
          </f-facet>
          <f-facet name="error" *ngIf="submitted && f.nameRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true" formControlName="addressRespondent"
          [required]="true">
          <f-facet name="error" *ngIf="submitted && f.addressRespondent.errors?.required">{{'validationWarning' |
            translate }}
          </f-facet>
          <f-facet name="error" *ngIf="submitted && f.addressRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true" formControlName="telephoneRespondent">
          <f-facet name="error" *ngIf="submitted && f.telephoneRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true" formControlName="emailRespondent"
          [required]="true" [type]="'email'">
          <f-facet name="error" *ngIf="submitted && f.emailRespondent.errors?.required">{{'validationWarning' |
            translate }}
          </f-facet>
          <f-facet name="error" *ngIf="f.emailRespondent.errors?.email">{{'validationEmail' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>

        <w-input-text label="{{'representedBy' | translate}}" [labelOnTop]="true"
          formControlName="representedByRespondent">
          <f-facet name="error" *ngIf="submitted && f.representedByRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true"
          formControlName="addressRepresentativeRespondent">
          <f-facet name="error" *ngIf="submitted && f.addressRepresentativeRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true"
          formControlName="telephoneRepresentativeRespondent">
          <f-facet name="error" *ngIf="submitted && f.telephoneRepresentativeRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true"
          formControlName="emailRepresentativeRespondent" [type]="'email'">
          <f-facet name="error" *ngIf="f.emailRepresentativeRespondent.errors?.email">{{'validationEmail' | translate }}
          </f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailRepresentativeRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>
  </w-edit-panel>

</form>

<app-additional-party #additionalPartyForm  [(ngModel)]="additionalParties" ></app-additional-party>
<br><br>

<form [formGroup]="requestOtherForm" (ngSubmit)="onSubmit()">

  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-textarea label="{{'disputeMessage' | translate}}" [required]="true" [placeholder]="''"
          formControlName="disputeDescription">
          <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
            <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.maxlength">{{'textLengthWarning' | translate: {value: '18000'} }}</f-facet>
            <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.wordCount">{{'ValidateWord' | translate}}</f-facet>
        </w-input-textarea>
      </w-slot>
    </w-section>

    <w-section>
      <f-facet name="header" [innerHTML]="'ipportalDefault.mediation.agreement' | translate"></f-facet>
      <w-slot>
        <w-input-file label="{{'annexTitle' | translate}}" [isLarge]="false" [auto]="false" [multiple]="false"
          [sizeLimit]="10000000" [labelSelect]="'addDocument' | translate"
          [invalidFileSizeMessageSummary]="'ValidFileSize' | translate" [showUploadButton]="false" [auto]="false"
          [required]="true" (wSelected)="onAgreementChange($event)">
          <f-facet name="error" *ngIf="submitted && g.agreement.errors">{{'validationFile' | translate}}</f-facet>
        </w-input-file>
      </w-slot>
    </w-section>

    <w-section>
      <f-facet name="header" *ngIf="collaboration === 'NONE'"
        [innerHTML]="'ipportalDefault.mediation.proofPayment' | translate"> </f-facet>
      <f-facet name="header" *ngIf="collaboration !== 'NONE'"
        [innerHTML]="'mcstForm.mediation.proofPayment' | translate"> </f-facet>
      <w-slot>
        <w-input-file label="{{'annexTitle' | translate}}" [isLarge]="false" [auto]="false" [multiple]="false"
          [sizeLimit]="10000000" [labelSelect]="'addDocument' | translate"
          [invalidFileSizeMessageSummary]="'ValidFileSize' | translate" [showUploadButton]="false" [auto]="false"
          (wSelected)="onPaymentChange($event)">
        </w-input-file>
      </w-slot>
    </w-section>

    <w-section>
      <f-facet name="header">{{ 'otherDocIfAny' | translate }}</f-facet>
      <w-slot>
        <w-input-file label="{{'annexTitle' | translate}}" [isLarge]="false" [auto]="false" [multiple]="false"
          [sizeLimit]="10000000" [labelSelect]="'addDocument' | translate"
          [invalidFileSizeMessageSummary]="'ValidFileSize' | translate" [showUploadButton]="false" [auto]="false"
          (wSelected)="onAttachmentChange($event)">
        </w-input-file>
      </w-slot>
    </w-section>

    <w-section>
      <w-slot>
        <w-input-text label="{{'submittingIndividual' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="signature">
          <f-facet name="error" *ngIf="submitted && g.signature.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && g.signature.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>
  </w-edit-panel>

  <w-view [center]="false" [textPosition]="'start'">
    <f-facet name="description">
      <re-captcha formControlName="g_recaptcha_response" siteKey="{{siteKey}}"></re-captcha>
    </f-facet>
    <f-facet name="footer" *ngIf="submitted && g.g_recaptcha_response.errors?.required">
      <w-validation-panel [type]="'error'" [summary]="'validationCaptch' | translate"></w-validation-panel>
    </f-facet>
  </w-view>




</form>


<f-facet name="step-info">
  <w-page-header [disableUppercase]="true" [showPortfolio]="false">
    <f-facet name="actions">
      <button w-button [isDefault]="true" [isSecondary]="false" [disabled]="false" [buttonType]="'text'" 
        type="submit" (click)="onSubmit()">
        {{ 'submitRequest' | translate }}
      </button>
    </f-facet>
  </w-page-header>
  <br><br>
</f-facet>

<w-dialog *ngIf="isOpen" [style]="style">
  <w-step [title]="'ipportalDefault.mediation.alerTitle' | translate" [reviewMode]="true">
    <div facet-step-info [innerHTML]="'ipportalDefault.mediation.alerText' | translate"></div>
    <button w-button (click)="closeDialogbox()" facet-step-actions>OK</button>
  </w-step>
</w-dialog>