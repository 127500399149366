<form [formGroup]="firstForm" >
  <w-page-header [title]="'mcstForm.tree.pageTitle' | translate"></w-page-header>
  <w-step>
    <p facet-step-info  [innerHTML]="'mcstForm.tree.pageDesc' | translate "></p>
  </w-step>

  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-radio label="{{'mcstForm.tree.Optionlabel' | translate}}" (change)="selectFirstOption(this.firstForm)"
         (click)="selectFirstOption(this.firstForm)" [options]="'mcstForm.tree.copyrightOptions' | translate " 
            formControlName="selectFirstOptionValue">
        </w-input-radio>
      </w-slot>
    </w-section>
  </w-edit-panel>
</form>
 
<!-- Second Option Show -->
<div *ngIf="secondFormInformation && secondFormInformation.selectedArea && secondFormName ==='involvedcopyrghtForm' ">
  <form [formGroup]="involvedcopyrghtForm" > 
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="{{secondFormInformation.selectLabel}}" (change)="selectSecondOption(this.involvedcopyrghtForm)"
           (click)="selectSecondOption(this.involvedcopyrghtForm)"
           [options]="secondFormInformation.selectOptions" formControlName="selectSecondOptionValue">
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>
</div>

<div *ngIf="secondFormInformation && secondFormInformation.selectedArea && secondFormName ==='informationcopyrghtForm'">
  <form [formGroup]="informationcopyrghtForm" > 
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="{{secondFormInformation.selectLabel}}" (change)="selectSecondOption(this.informationcopyrghtForm)"
           (click)="selectSecondOption(this.informationcopyrghtForm)"
           [options]="secondFormInformation.selectOptions" formControlName="selectSecondOptionValue">
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>
</div>

<div *ngIf="secondFormInformation && secondFormInformation.selectedArea && secondFormName ==='informationresolutionForm'">
  <form [formGroup]="informationresolutionForm" > 
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="{{secondFormInformation.selectLabel}}" (change)="selectSecondOption(this.informationresolutionForm)"
           (click)="selectSecondOption(this.informationresolutionForm)"
           [options]="secondFormInformation.selectOptions" formControlName="selectSecondOptionValue">
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>
</div>

<!-- <div *ngIf="thirdFormInformation && thirdFormInformation.selectedArea"> 
  <tree-contract   [options]="thirdFormInformation.selectOptions" [formName]="thirdFormName"  (selectVal)="selectThirdOption($event)"></tree-contract>
</div> -->


<div *ngIf="thirdFormInformation && thirdFormInformation.selectedArea && this.thirdFormName==='contractanotherpartyForm'"> 
  <form [formGroup]="contractanotherpartyForm" >
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio [options]="thirdFormInformation.selectOptions" 
          label="{{thirdFormInformation.selectLabel}}" 
          formControlName="selectThirdOptionValue"
          (click)="selectThirdOption(this.contractanotherpartyForm)" 
          (change)="selectThirdOption(this.contractanotherpartyForm)" >
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>
</div>



<div *ngIf="thirdFormInformation && thirdFormInformation.selectedArea && this.thirdFormName==='noncontractanotherpartyForm'"> 
    <form [formGroup]="noncontractanotherpartyForm" >
      <w-edit-panel>
        <w-section>
          <w-slot>
            <w-input-radio [options]="thirdFormInformation.selectOptions" 
            label="{{thirdFormInformation.selectLabel}}" 
            formControlName="selectThirdOptionValue"
            (click)="selectThirdOption(this.noncontractanotherpartyForm)" 
            (change)="selectThirdOption(this.noncontractanotherpartyForm)" >
            </w-input-radio>
          </w-slot>
        </w-section>
      </w-edit-panel>
    </form>
  </div>


<!-- Froth Option Show  -->
<div *ngIf="forthFormInformation && forthFormInformation.selectedArea && this.forthFormName==='nowipomediationForm'">
  <form [formGroup]="nowipomediationForm" >
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="{{forthFormInformation.selectLabel}}" (change)="selectForthOption(this.nowipomediationForm)" 
          (click)="selectForthOption(this.nowipomediationForm)"
           [options]="forthFormInformation.selectOptions" formControlName="selectForthOptionValue">
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>
</div>



<!-- link for all button -->
<div *ngIf="linkinfo && linkinfo.SelectLink">
  <w-step collapsible="false" reviewMode=true>
    <p facet-step-info [innerHTML]="linkinfo.selectTitleLabel"></p>
    <div facet-step-actions [style]="linkinfo.position ==='left' ? '': 'float:right'" *ngIf="linkinfo.selectButtonLabel">
       <button w-button [isDefault]="true" (click)="showLink(linkinfo.linkValue)"
        [buttonType]="'text'"> {{linkinfo.selectButtonLabel}}
      </button>
    </div>
  </w-step>
</div>



























