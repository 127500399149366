export const environment = {
  production: false,
  type:'DEV',
  contextIsRoot: false,
  requestPaymentLink:'https://www3dev.wipo.int/amc-payment/dcti-request-form',
  answerPaymentLink:'https://www3dev.wipo.int/amc-payment/dcti-answer-form',
  webComponentsPath: 'https://cdn.ipp-navbar.ipportal.dev.web1.wipo.int',
  apiUrl: 'https://www3dev.wipo.int/amc-forms-rest/',
  recaptchaSiteKey: '6Ldn7i4pAAAAAIqxEpTkbLQwkexTCys_bFq71bqC',
  cookieDomain: '.wipo.int',
  languageQueryParamName: 'lang',
  siteUrl: 'https://www3dev.wipo.int/',
  hostName: window.location.origin + '/amc-forms',


  /* Authentication settings */
  oidcUserinfo: '/am/oauth2/userinfo',
  oidcIssuer: 'https://www3dev.wipo.int:443/am/oauth2',
  oidcClientId: 'amcFormsDev',
  oidcRedirectUri: window.location.origin + '/amc-forms/login',
  oidcPostLogoutRedirectUri: 'https://www3dev.wipo.int/wipoaccounts/generic/public/logout.xhtml?returnURL=https://www3dev.wipo.int/amc-forms',
  oidcSilentRedirectUri: window.location.origin + '/amc-forms/silent-refresh.html',
  oidcScope: 'openid profile email',

  ssoCookieName: 'amdssocookie',
  ssoUseridUrl: 'https://www3dev.wipo.int/sso/userid',

  refreshTimeout: 0.7
};
