import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdrYoungRegistrationComponent } from './pages/adryoung/registration.component';
import { ExpeditedArbitrationComponent } from './pages/expedited-arbitration/expedited-arbitration.component';
import { RequestArbitrationComponent } from './pages/request-arbitration/request-arbitration.component';
import { RequestGoodOfficesServicesComponent } from './pages/request-good-offices-services/request-good-offices-services.component';
import { RequestMediationComponent } from './pages/request-mediation/request-mediation.component';
import { UnilateralRequestMediationComponent } from './pages/unilateral-request-mediation/unilateral-request-mediation.component';
import { WipoMcstDecisionTreeComponent } from './pages/wipo-mcst-decision-tree/wipo-mcst-decision-tree.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'dcti',
    loadChildren: () => import('./pages/dcti/dcti.module').then(m => m.DCTIModule)
  },
  {
    path: ':lang/dcti',
    loadChildren: () => import('./pages/dcti/dcti.module').then(m => m.DCTIModule)
  },

  {
    path: 'office/private',
    loadChildren: () => import('./pages/offices-fe-private-services/offices-fe-private-services.module').then(m => m.OfficesFePrivateServicesModule)
  },
  {
    path: ':lang/office/private',
    loadChildren: () => import('./pages/offices-fe-private-services/offices-fe-private-services.module').then(m => m.OfficesFePrivateServicesModule)
  },
  {
    path: ':lang/office/:officeName/case',
    loadChildren: () => import('./pages/offices-fe-services/offices-fe-services.module').then(m => m.OfficesFeServicesModule)
  },
  {
    path: 'es',
    children: [
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent },
    
    ]
  },
  {
    path: 'en',
    children: [
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent },
      { path: 'rok/mcst', component: WipoMcstDecisionTreeComponent, data: { collaboration: 'ROK/MCST' }  },
      { path: 'rok/mcst/good-offices-services', component: RequestGoodOfficesServicesComponent, data: { collaboration: 'ROK/MCST' } },
      { path: 'rok/mcst/mediation', component: RequestMediationComponent, data: { collaboration: 'ROK/MCST' } },
      { path: 'rok/mcst/unilateral-mediation', component: UnilateralRequestMediationComponent, data: { collaboration: 'ROK/MCST' } }
    ]
  },
  {
    path: 'ko',
    children: [
      { path: 'rok/mcst', component: WipoMcstDecisionTreeComponent, data: { collaboration: 'ROK/MCST' }  },
      { path: 'rok/mcst/good-offices-services', component: RequestGoodOfficesServicesComponent, data: { collaboration: 'ROK/MCST' } },
      { path: 'rok/mcst/mediation', component: RequestMediationComponent, data: { collaboration: 'ROK/MCST' } },
      { path: 'rok/mcst/unilateral-mediation', component: UnilateralRequestMediationComponent, data: { collaboration: 'ROK/MCST' } },
    ]
  },
  {
    path: 'ar',
    children: [
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent }
    ]
  },

  {
    path: 'ru',
    children: [
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent }
    ]
  },

  {
    path: 'zh',
    children: [
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent }
    ]
  },

  {
    path: 'fr',
    children: [
      { path: 'adr/mediation', component: RequestMediationComponent },
      { path: 'adr/unilateral-mediation', component: UnilateralRequestMediationComponent },
      { path: 'adr/arbitration', component: RequestArbitrationComponent },
      { path: 'adr/expedited-arbitration', component: ExpeditedArbitrationComponent },
      { path: 'adr/good-offices-services', component: RequestGoodOfficesServicesComponent },
      { path: 'adryoung/registration', component: AdrYoungRegistrationComponent }
    ]
  },


  { path: 'en/rok', redirectTo: 'en/rok/mcst', pathMatch: 'full' },
  { path: 'en/adryoung', redirectTo: 'en/adryoung/registration', pathMatch: 'full' },
  { path: 'en/adr', redirectTo: 'en/adr/mediation', pathMatch: 'full' },
  { path: 'rok/mcst', redirectTo: 'ko/rok/mcst', pathMatch: 'full' },
  { path: 'rok/mcst/good-offices-services',  redirectTo: 'ko/rok/mcst/good-offices-services', pathMatch: 'full' },
  { path: 'rok/mcst/mediation',  redirectTo: 'ko/rok/mcst/mediation', pathMatch: 'full' },
  { path: 'rok/mcst/unilateral-mediation',  redirectTo: 'ko/rok/mcst/unilateral-mediation', pathMatch: 'full' },
  { path: 'rok',  redirectTo: 'ko/rok/mcst', pathMatch: 'full' },
  { path: 'adr/good-offices-services', redirectTo: 'en/adr/good-offices-services', pathMatch: 'full' },
  { path: 'adr/mediation', redirectTo: 'en/adr/mediation', pathMatch: 'full'},
  { path: 'adr/unilateral-mediation', redirectTo: 'en/adr/unilateral-mediation', pathMatch: 'full' },
  { path: 'adr/arbitration',redirectTo: 'en/adr/arbitration', pathMatch: 'full' },
  { path: 'adr/expedited-arbitration',redirectTo: 'en/adr/expedited-arbitration', pathMatch: 'full' },
  { path: 'domains', redirectTo: 'en/domains', pathMatch: 'full'},
  { path: 'adryoung', redirectTo: 'en/adryoung/registration', pathMatch: 'full' },
  { path: 'adryoung/registration', redirectTo: 'en/adryoung/registration', pathMatch: 'full' },
  { path: 'adr', redirectTo: 'en/adr/mediation', pathMatch: 'full' },
  { path: 'ko/rok', redirectTo: 'ko/rok/mcst', pathMatch: 'full' },
  { path: 'ko/adr', redirectTo: 'ko/adr/mediation', pathMatch: 'full' },


 
  { path: 'request', redirectTo: 'en/dcti/request', pathMatch: 'full'},
  { path: 'answer', redirectTo: 'en/dcti/answer', pathMatch: 'full'},
  { path: 'statement', redirectTo: 'en/dcti/statement-of-acceptance', pathMatch: 'full'},

  { path: 'office/:officeName/case', redirectTo: 'es/office/:officeName/case', pathMatch: 'full'},
 
  { path: '', redirectTo: 'en/office/private', pathMatch: 'full' },
 
  { path: 'page-not-found',component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
