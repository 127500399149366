import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryModel } from '../_models/country-model';


@Injectable({ providedIn: 'root' })
export class UploadService {

  constructor(private http: HttpClient) { }


  getCountriesList(): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>('http://restcountries.eu/rest/v2/all?fields=name');
  }


  submitGoodOfficesServicesForm(formdata: FormData): Observable<HttpEvent<{}>> {

    const req = new HttpRequest('POST', environment.apiUrl + 'good-offices-services/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitExpeditedArbitrationForm(formdata: FormData): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', environment.apiUrl + 'expedited-arbitrations/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitArbitrationForm(formdata: FormData): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', environment.apiUrl + 'arbitrations/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitMediationForm(formdata: FormData): Observable<HttpEvent<{}>> {

    const req = new HttpRequest('POST', environment.apiUrl + 'mediations/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitUnilateralMediationForm(formdata: FormData): Observable<HttpEvent<{}>> {
    console.log(' environment.apiUrl ----', environment.type )
    const req = new HttpRequest('POST', environment.apiUrl + 'unilateral-mediations/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitAdrYoungRegistrationForm(formdata: FormData): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', environment.apiUrl + 'adr-young-registration', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }

  submitOfficesMediationForm(formdata: FormData): Observable<HttpEvent<{}>> {

    const req = new HttpRequest('POST', environment.apiUrl + 'api/offices/mediation/submit-request', formdata, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.http.request(req);

  }


}
