import {Injectable} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PrivateService } from '../_services/private.service';
import { officeConfiguration } from '../_models/case-configuration';
import { FormControl, ValidationErrors } from '@angular/forms';
import DOMPurify from 'dompurify';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountryModel,customeModel } from '../_models/country-model';
import countries from 'src/assets/json/countries-wipo.json';
import { CookieService } from 'ngx-cookie-service';
import customeOptions  from 'src/assets/json/customeOptions.json';
import { createEvent, download } from 'src/app/_services/event-download.utils';
import * as XLSX from 'xlsx';

const TOTAL_WORD_SIZE:number  = 3000;

@Injectable({
  providedIn: 'root' // Just do @Injectable() if you're not on Angular v6+
})

export class UtilsService {
  
  AMC_LANGUAGE_COOKIE = 'amc_language'; // WIPO language standard cookie name
  officeConfigDetailsSubject = new BehaviorSubject([]);
  countryOptions = [];
  customeOptions = [];
  countryList = countries;
  currentLanguage = 'en';
  optionMeetingMinutes = ['00', 30];
  optionMeetingTime = [];

  optionsCountries: any[] = [];
  optionsCaseOutcome: any[] = [];
  optionstypeOfDispute: any[] = [];
  genderOptions: any[] = [];
  attendanceOptions :any[] = [];
  enforcementOptions :any[] = [];
  typeOfPartyOptions :any[] = [];
  countryCache:any[] = [];
  caseOutCache:any[] = [];
  typeOfDisputeCache:any[] = [];
  enforcementCache : any[] = [];
  typeOfPartyCache:any[]=[];
  genderCache:any[]=[];

  blocked = false;
    constructor( private router: Router , private service: PrivateService,private cookieService: CookieService,
       private activatedRoute: ActivatedRoute, private translate: TranslateService) { }

       loadTime() {
        for (let index = 1; index <= 23; index++) {
          let h = index >= 12 ? "PM" : "AM"
          for (const element of this.optionMeetingMinutes) {
            let digit = this.hasOneDigit(index) ? '0' + index : index;
             this.optionMeetingTime.push({ "label": digit + ":" + element + " " + h, "value": digit + ":" + element });
            
          }
        }
        return this.optionMeetingTime;
       }

       extractTime(input) {
        if (input) {
          let time = input?.split('T')
          let timeVal = time[1]?.split(":")
          // Format the time as HH:MM
          const formattedTime = timeVal[0]+":"+timeVal[1];
          return formattedTime; 
       } else {
        return null;
       }
      
      }

      loadOptions(optionsVal) {
        this.customeOptions = [];
        let options:customeModel;
        optionsVal.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      loadGenderOptions() {
        this.customeOptions = [];
        let options:customeModel;
        customeOptions.genderOptions.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      getGenderOptions() {
        const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        if (this.currentLanguage === language && this.genderCache.length > 0) {
          return this.genderCache;
        }
        this.currentLanguage = language;
        this.genderCache = this.loadGenderOptions();
        // console.log(' this.genderCache---', this.genderCache)
        return this.genderCache;
      }
      
      loadtypeOfPartyOptions() {
        this.customeOptions = [];
        let options:customeModel;
        customeOptions.typeOfPartyOptions.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      getTypeOfPartyOptions() {
        const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        if (this.currentLanguage === language && this.typeOfPartyCache.length > 0) {
          return this.typeOfPartyCache;
        }
        this.currentLanguage = language;
        this.typeOfPartyCache = this.loadtypeOfPartyOptions();
        // console.log(' this.typeOfPartyCache---', this.typeOfPartyCache)
        return this.typeOfPartyCache;
      }

      loadEnforcementOptions() {
        this.customeOptions = [];
        let options:customeModel;
        customeOptions.enforcementOptions.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      getEnforcementOptions() {
        const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        if (this.currentLanguage === language && this.enforcementCache.length > 0) {
          return this.enforcementCache;
        }
        this.currentLanguage = language;
        this.enforcementCache = this.loadEnforcementOptions();
        // console.log(' this.enforcementCache---', this.enforcementCache)
        return this.enforcementCache;
      }

      loadTypeOfDisputeOptions() {
        this.customeOptions = [];
        let options:customeModel;
        customeOptions.typeOfDisputeOptions.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      getTypeOfDisputeOptions() {
        const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        if (this.currentLanguage === language && this.typeOfDisputeCache.length > 0) {
          return this.typeOfDisputeCache;
        }
        this.currentLanguage = language;
        this.typeOfDisputeCache = this.loadTypeOfDisputeOptions();
        // console.log(' this.typeOfDisputeCache---', this.typeOfDisputeCache)
        return this.typeOfDisputeCache;
      }

      loadCaseOutOptions() {
        this.customeOptions = [];
        let options:customeModel;
        customeOptions.caseOutcomeOptions.forEach(element => { 
          options = new customeModel();
          options.label = this.translate.instant(element.label);
          options.value = element.value;
          this.customeOptions.push(options);
        });
        return this.customeOptions;
      }

      getCaseOutOptions() {
        const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        if (this.currentLanguage === language && this.caseOutCache.length > 0) {
          return this.caseOutCache;
        }
        this.currentLanguage = language;
        this.caseOutCache = this.loadCaseOutOptions();
        // console.log(' this.caseOutCache---', this.caseOutCache)
        return this.caseOutCache;
      }


        getCountryOptions() {
          const language = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
          if (this.currentLanguage === language && this.countryCache.length > 0) {
            return this.countryCache;
          }
          this.currentLanguage = language;
          this.countryCache = this.loadCountryOption();
          // console.log(' this.countryCache---', this.countryCache)
          return this.countryCache;
        }

       loadCountryOption() {
        this.currentLanguage = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
        this.countryOptions = [];
        let country: CountryModel;
        this.countryList.forEach(element => {
          country = new CountryModel();
          if(this.currentLanguage === 'es')  {
            country.label = element["es-DisplayName"];
            country.value = element["es-DisplayName"];
          } else if(this.currentLanguage === 'fr')  {
            country.label = element["fr-DisplayName"];
            country.value = element["fr-DisplayName"];
          } else  { 
            country.label = element.name;
            country.value = element.name;
          } 
          this.countryOptions.push(country);
          
        });
        return this.countryOptions.sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0));
      }

       isBlank(value: any): boolean {
        if (value !== null && value !== undefined) {
          if ((typeof value === 'string' && value.trim().length == 0) ||
            (value instanceof Array && value.length == 0))
            return true;
          else
            return false;
        }
        return true;
      }


      customWordCountValidator(control: FormControl): ValidationErrors | null {
        const words = control?.value?.split(/\s+/).length;
        if (words > TOTAL_WORD_SIZE) {
          return { wordCount: true }; // Custom error key
        }
        return null;
      }

    officeConfigDetails(officeName) {
      return new Promise<any>((resolve) => {
        this.service.getOfficeConfiguration(officeName).subscribe((response: officeConfiguration[]) => {
          if (response) {
            resolve(response)
          }
        });
      });
    }

    async verifyOfficeConfiguration(office): Promise<any[]> {
      try {
        const response = await this.service.getOfficeConfiguration(office).toPromise();
        if (!response) {
          this.router.navigate(['page-not-found']);
        }
        return response;
      } catch (error) {
        // handle error
        console.error(error);
      }
    }

  

    createInternalCase(currentLang,officeName) {
        this.router.navigate(['/' + currentLang + '/office/private/' + officeName.toLowerCase() + '/register-case']);
    }

    async getFilter(filterValue: any, casesDetails) {
      if (filterValue) {
          const filterValueLower = filterValue.toLowerCase();
             return casesDetails.filter((el: any) =>
              el?.nameClaimant?.toLowerCase().includes(filterValueLower) ||
              el?.emailClaimant?.toLowerCase().includes(filterValueLower) ||
              el?.telephoneClaimant?.toLowerCase().includes(filterValueLower) ||
              el?.nameRespondent?.toLowerCase().includes(filterValueLower) ||
              el?.emailRespondent?.toLowerCase().includes(filterValueLower) ||
              el?.telephoneRespondent?.toLowerCase().includes(filterValueLower) ||
              el?.nameRespondentRepBy?.toLowerCase().includes(filterValueLower) ||
              el?.notes?.toLowerCase().includes(filterValueLower) );
      }
  }


  countChars(textString) {
    // console.log('textString?.length---',textString?.length)
    return textString?.length > 255 ? true : false;
  }

  validateEmail(email: FormControl): ValidationErrors | null {
    let emailValue = email.value;
    if (emailValue) {
      // Validate each email address using a regex
      const EMAIL_REGEXP = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      // Check if any email address is invalid
      if (!EMAIL_REGEXP.test(emailValue)) {
        return { email: true };
      }
      return null;
    }
  }

  redirctURLperlanguage(selectedLanguage,paramsLang) {
    // this.officeDetailsSubject.next(officeDetails);
    if (selectedLanguage) {
      const selectLang = Array.isArray(selectedLanguage) ? selectedLanguage[0].toLowerCase() : selectedLanguage.toLowerCase();
      if (selectLang === paramsLang) {
        return true;
      } else if(!selectedLanguage.includes(paramsLang.toUpperCase())) {  
          this.replaceLanguageUrl(selectLang, paramsLang); 
      }
    }
  }


  replaceLanguageUrl(selectedLanguage,paramLanguage){
    let newURL = this.router.url.replace(paramLanguage,selectedLanguage);
    const encodedInput = DOMPurify.sanitize(this.encodeHTML(environment.hostName + newURL));
    window.location.href = encodedInput;
  }

  encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
  }


  convertFormat(isoDate) { 
    if(isoDate) {
      let date1 = new Date(isoDate);
      let getMonth = date1.getMonth() + 1;
      let month = this.hasOneDigit(getMonth) ? '0' + getMonth : getMonth;
      return   date1.getFullYear() + '-' + month + '-' + date1.getDate();
    } else 
      return '';
}

  setDateFormat(value: any) {
  if (value === null) {
    return null;
  }
  const rt = new Date(value);
  return rt;
  }


  hasOneDigit(val) {
    return String(Math.abs(val)).charAt(0) == val;
  }


  loadOptionsList() {
    this.optionsCountries = this.loadCountryOption();
    this.optionsCaseOutcome = this.loadOptions(customeOptions.caseOutcomeOptions);
    this.optionstypeOfDispute = this.loadOptions(customeOptions.typeOfDisputeOptions);
    this.attendanceOptions = this.loadOptions(customeOptions.attendanceOptions);
    this.enforcementOptions = this.loadOptions(customeOptions.enforcementOptions);
    this.typeOfPartyOptions = this.loadOptions(customeOptions.typeOfPartyOptions);
    this.genderOptions = this.loadOptions(customeOptions.genderOptions);
  }

  async getOfficeDetails(officeName){
    let officeDetails = await this.service.getOfficeConfiguration(officeName).toPromise();
    return  officeDetails;
  }

  async getHeader(officeName,officeDetails,caseStatus) {
    let HEADERS: any = [];
  
    HEADERS.push(this.translate.instant('common.dateReceived'));

    if(caseStatus === 'case') {
      HEADERS.push(this.translate.instant('office.cases.caseNo'));
    } else {
      HEADERS.push(this.translate.instant('office.preCases.preCaseNo'));
    }

    HEADERS.push(this.translate.instant('common.fileNumber'));

    if(officeDetails?.folioNumberActive  === 1)
      HEADERS.push(this.translate.instant('common.folioNumber'));

    if(officeDetails.filePageNumberActive  === 1)
      HEADERS.push(this.translate.instant('common.filePageNumber'));

    HEADERS.push(
      this.translate.instant('common.paymentOfFees'),
      this.translate.instant('common.mediator'),
      this.translate.instant('options.caseOutcomeVal.pending'),
      this.translate.instant('options.caseOutcomeVal.completeReq'),
      this.translate.instant('options.caseOutcomeVal.Settled'),
      this.translate.instant('options.caseOutcomeVal.inactive'),
      this.translate.instant('options.caseOutcomeVal.archived'),
      this.translate.instant('options.caseOutcomeVal.expired'),
      this.translate.instant('options.caseOutcomeVal.joinder'),
      this.translate.instant('options.caseOutcomeVal.dismissed'),
      this.translate.instant('options.caseOutcomeVal.notAmended'),
      this.translate.instant('options.caseOutcomeVal.other')
    );

    HEADERS.push(`"${this.translate.instant('common.caseOutcomeOther')}"`);

    if(officeDetails?.typeOfDisputeActive  === 1) {
      HEADERS.push(
        this.translate.instant('options.typeOfDispute.rPayment'),
        this.translate.instant('options.typeOfDispute.bContract'),
        this.translate.instant('options.typeOfDispute.rights'),
        this.translate.instant('options.typeOfDispute.image'),
        this.translate.instant('options.typeOfDispute.useRights'),
        this.translate.instant('options.typeOfDispute.infringement'),
        this.translate.instant('options.typeOfDispute.cultures'),
        this.translate.instant('options.typeOfDispute.other')
      );
      HEADERS.push(`"${this.translate.instant('common.typeOfDisputeOther')}"`);
    }

    if(officeDetails?.typeOfDisputeTxtActive  === 1) {
      HEADERS.push(this.translate.instant('common.typeOfDisputeTxt'));
    }

    HEADERS.push(
      this.translate.instant('common.dateOfLastUpdate'),
      this.translate.instant('common.nextDeadline'),
      this.translate.instant('common.nrOfMeetings'),
    );

    if(officeDetails.attendanceActive  === 1) {
      HEADERS.push(this.translate.instant('common.attendanceAttended'));
      HEADERS.push(this.translate.instant('common.attendanceNotAttended'));
    }

    if(officeDetails.finesActive  === 1)
      HEADERS.push(this.translate.instant('common.fines'));

    if(officeDetails.enforcementActive  === 1) {
      HEADERS.push(this.translate.instant('options.enforcement.agreement'));
      HEADERS.push(this.translate.instant('options.enforcement.judgment'));
    }

    HEADERS.push(
      this.translate.instant('common.dateOfNextMeeting'),
      this.translate.instant('office.CSV.RequestingParty'),
      this.translate.instant('office.CSV.RequestingPartyEmail'),
      this.translate.instant('office.CSV.RequestingPartyAddress'),
      this.translate.instant('office.CSV.RequestingPartyCountry'),
      this.translate.instant('common.stateOrCountyClaimant'),
      this.translate.instant('office.CSV.RequestingPartyPhoneNumber'),
      this.translate.instant('options.typeOfParty.individual'),
      this.translate.instant('options.typeOfParty.company'),
      this.translate.instant('options.typeOfParty.CMO'),
      this.translate.instant('options.typeOfParty.softwarecompany'),
      this.translate.instant('options.typeOfParty.Other'),
      `"${this.translate.instant('common.typeClaimantOther')}"`,
      this.translate.instant('options.gender.female'),
      this.translate.instant('options.gender.male'),
      this.translate.instant('options.gender.Other'),
      this.translate.instant('office.CSV.RequestingPartyRepresentative'),
      this.translate.instant('office.CSV.RequestingPartyRepresentativeEmail'),
      this.translate.instant('office.CSV.RequestingPartyRepresentativeAddress'),
      this.translate.instant('office.CSV.RequestingPartyRepresentativeCountry'),
      this.translate.instant('office.CSV.RequestingPartyRepresentativePhoneNumber'),
      this.translate.instant('office.CSV.RespondingParty'),
      this.translate.instant('office.CSV.RespondingPartyEmail'),
      this.translate.instant('office.CSV.RespondingPartyAddress'),
      this.translate.instant('office.CSV.RespondingPartyCountry'),
      this.translate.instant('common.stateOrCountyRespondent'),
      this.translate.instant('office.CSV.RequestedPartyPhoneNumber'),
      this.translate.instant('options.typeOfParty.individual'),
      this.translate.instant('options.typeOfParty.company'),
      this.translate.instant('options.typeOfParty.CMO'),
      this.translate.instant('options.typeOfParty.softwarecompany'),
      this.translate.instant('options.typeOfParty.Other'),
      `"${this.translate.instant('common.typeRespondentOther')}"`,
      this.translate.instant('options.gender.female'),
      this.translate.instant('options.gender.male'),
      this.translate.instant('options.gender.Other'),
      this.translate.instant('office.CSV.RespondingPartyRepresentative'),
      this.translate.instant('office.CSV.RespondingPartyRepresentativeEmail'),
      this.translate.instant('office.CSV.RespondingPartyRepresentativeAddress'),
      this.translate.instant('office.CSV.RespondingPartyRepresentativeCountry'),
      this.translate.instant('office.CSV.RequestedPartyRepresentativePhoneNumber'),
      this.translate.instant('common.status'),
      this.translate.instant('office.' + officeName + '.public.case.disputeMessage'),
      this.translate.instant('common.notes')
    );

  
    return HEADERS;

  
  }

  async appenDetails(element,officeDetails,csv,caseStatus) {
    let caseNo = '';
    let dateVal = this.convertFormat(element.dateReceived);
    caseNo = (caseStatus === 'precase') ? element.code : element.officeCaseNumber;
    let fileNumber = (element.fileNumber) ?  element.fileNumber : '';
    let folioNumber = (element.folioNumber) ?  element.folioNumber : '';
    let filePageNumber = (element.filePageNumber) ?  element.filePageNumber : '';
    let paymentOfFees = (element.paymentOfFees) ?  element.paymentOfFees : '';
    let mediator = (element.mediator) ?  element.mediator : '';
    let caseOutcomeOther = (element.caseOutcomeOther) ?  element.caseOutcomeOther : '';

    let caseOutcome = [];
    let additionalPartycaseOutcome = [];
    let loop1 = 0;
    for(let i=1;i<=10;i++) {
    caseOutcome[loop1] = (element.caseOutcome?.includes(i)) ?  1 : "";
    additionalPartycaseOutcome[loop1] = "";
    loop1++;
    }
   
    let appendtypeOfDispute = [];
    let additionalPartyTypeOfDispute = [];
    if(officeDetails?.typeOfDisputeActive  === 1) {
      let loop2 = 0;
      for(let i=1;i<=8;i++) {
        appendtypeOfDispute[loop2] = (element.typeOfDispute?.includes(i)) ?  1 : "";
        additionalPartyTypeOfDispute[loop2] = "";
        loop2++;
      }
    }
  
    let typeOfDisputeTxt = '';
    if(officeDetails?.typeOfDisputeTxtActive  === 1) {
      typeOfDisputeTxt = (element.typeOfDisputeTxt) ?  element.typeOfDisputeTxt : '';
    }

    let typeOfDisputeOther  = (element.typeOfDisputeOther) ? element.typeOfDisputeOther : '';
    let dateOfLastUpdate  = (element.dateOfLastUpdate) ? this.convertFormat(element.dateOfLastUpdate) : '';
    let nextDeadline  = (element.nextDeadline) ? this.convertFormat(element.nextDeadline) : '';
    let nrOfMeetings  = (element.nrOfMeetings) ? element.nrOfMeetings : '';

    let appendattendance  = (element.attendanceAttended) ? element.attendanceAttended : '';
    let appendNotattendance  = (element.attendanceNotAttended) ? element.attendanceNotAttended : '';

    let  fines = (element.fines) ? element.fines : '';

    let appendenforcement = [];
    let additionalPartyForcement = [];
    let loop4 =0;
    for(let i=1;i<=2;i++) {
      appendenforcement[loop4] = (element.enforcement === i) ?  1 : "";
      additionalPartyForcement[loop4] = "";
      loop4++;
    }
    
    let  dateOfNextMeeting = (element.dateOfNextMeeting) ? this.convertFormat(element.dateOfNextMeeting) : '';
    let  nameClaimant = (element.nameClaimant) ? element.nameClaimant : '';
    let  emailClaimant = (element.emailClaimant) ?  element.emailClaimant  : '';
    let  telephoneClaimant = (element.telephoneClaimant) ?   element.telephoneClaimant  : '';
    let  addressClaimant = (element.addressClaimant) ?   element.addressClaimant  : '';
    let  stateOrCountyClaimant  = (element.stateOrCountyClaimant) ?   element.stateOrCountyClaimant  : '';
    let  countryClaimant  = (element.countryClaimant) ?   element.countryClaimant  : '';
    
    let appendtypeClaimant = [];
    let loop5=0;
    for(let i=1;i<=5;i++) {
      appendtypeClaimant[loop5] = (element.typeClaimant === i) ?  1 : "";
      loop5++;
    }

    let typeClaimantOther = (element.typeClaimantOther) ? element.typeClaimantOther  : '';

    let appendclaimantGender = [];
    let loop6=0;
    for(let i=1;i<=3;i++) {
      appendclaimantGender[loop6] = (element.genderClaimant === i) ?  1 : "";
      loop6++;
    }

    let nameClaimantRepBy = (element.nameClaimantRepBy) ? element.nameClaimantRepBy  : '';
    let emailClaimantRepBy = (element.emailClaimantRepBy) ?  element.emailClaimantRepBy  : '';
    let telephoneClaimantRepBy = (element.telephoneClaimantRepBy) ?  element.telephoneClaimantRepBy : '';
    let addressClaimantRepBy = (element.addressClaimantRepBy) ?  element.addressClaimantRepBy : '';
    let countryClaimantRepBy = (element.countryClaimantRepBy) ?  element.countryClaimantRepBy : '';

    let nameRespondent = (element.nameRespondent) ? element.nameRespondent  : '';
    let emailRespondent = (element.emailRespondent) ?  element.emailRespondent  : '';
    let telephoneRespondent = (element.telephoneRespondent) ?   element.telephoneRespondent  : '';
    let addressRespondant = (element.addressRespondent) ?   element.addressRespondent  : '';
    let stateOrCountyRespondent  = (element.stateOrCountyRespondent) ?   element.stateOrCountyRespondent  : '';
    let countryRespondant = (element.countryRespondent) ?   element.countryRespondent  : '';

    let appendtypeRespondent = [];
    let loop7=0;
    for(let i=1;i<=5;i++) {
      appendtypeRespondent[loop7] = (element.typeRespondent === i) ?  1 : "";
      loop7++;
    }
    
    let typeRespondentOther = ( element.typeRespondentOther) ? element.typeRespondentOther : '';

    let appendrespondentGender = [];
    let loop8 =0;
    for(let i=1;i<=3;i++) {
      appendrespondentGender[loop8] = (element.genderRespondent === i) ?  1 : "";
      loop8++;
    }

    let nameRespondentRepBy = ( element.nameRespondentRepBy) ? element.nameRespondentRepBy : '';
    let emailRespondentRepBy = (element.emailRespondentRepBy) ?  element.emailRespondentRepBy : '';
    let addressRespondantRepBy = (element.addressRespondentRepBy) ?   element.addressRespondentRepBy  : '';
    let countryRespondantRepBy = (element.countryRespondentRepBy) ?   element.countryRespondentRepBy  : '';
    let telephoneRespondentRepBy = (element.telephoneRespondentRepBy) ?  element.telephoneRespondentRepBy  : '';

    let status = this.translate.instant('common.'+element.status);

    let disputeDescription = (element.disputeDescription) ?  element.disputeDescription  : '';

    let notes = (element.notes) ?  element.notes.replace(//g, '\'').replace(//g, '\'').replace(//g, '&ndash;').replace(/[]/g, '&mdash;')  : '';

    let appendArr: any = [];

    appendArr.push(dateVal,caseNo,fileNumber);

    if(officeDetails?.folioNumberActive  === 1)   appendArr.push(folioNumber);

    if(officeDetails.filePageNumberActive  === 1)   appendArr.push(filePageNumber);

    appendArr.push(paymentOfFees ,mediator,...caseOutcome,caseOutcomeOther);
    if(officeDetails?.typeOfDisputeActive  === 1) {  appendArr.push(...appendtypeOfDispute,typeOfDisputeOther);  }
    if(officeDetails?.typeOfDisputeTxtActive  === 1)  appendArr.push(typeOfDisputeTxt);
    appendArr.push(dateOfLastUpdate, nextDeadline, nrOfMeetings);
    if(officeDetails.attendanceActive  === 1) { appendArr.push(appendattendance,appendNotattendance); }
    if(officeDetails.finesActive  === 1)     appendArr.push(fines);
    if(officeDetails.enforcementActive  === 1) { appendArr.push(...appendenforcement);  }

      appendArr.push(dateOfNextMeeting);
      appendArr.push(nameClaimant,emailClaimant,addressClaimant,countryClaimant,stateOrCountyClaimant,telephoneClaimant,...appendtypeClaimant,typeClaimantOther,...appendclaimantGender);
      appendArr.push(nameClaimantRepBy,emailClaimantRepBy,addressClaimantRepBy,countryClaimantRepBy,telephoneClaimantRepBy)
      appendArr.push(nameRespondent,emailRespondent,addressRespondant,countryRespondant,stateOrCountyRespondent,telephoneRespondent,...appendtypeRespondent,typeRespondentOther,...appendrespondentGender)
      appendArr.push(nameRespondentRepBy,emailRespondentRepBy,addressRespondantRepBy,countryRespondantRepBy,telephoneRespondentRepBy)
      appendArr.push(status , disputeDescription , notes );
      csv.push(appendArr);

      if(element.additionalParties) {
          let addParties = JSON.parse(element.additionalParties);
          addParties.forEach(ele => {
            let appendAdditionalArr:any = [];
            let name = (ele.name) ? ele.name : '';
            let email = (ele.email) ?  ele.email  : '';
            let address = (ele.address) ?   ele.address  : '';
            let country  = (ele.country) ?   ele.country  : '';
            let stateOrCounty = (ele.stateOrCounty) ?   ele.stateOrCounty  : '';
            let telephone = (ele.telephone) ?  ele.telephone  : '';
            let typeOfPartyOther = (ele.typeOfPartyOther) ?  ele.typeOfPartyOther  : '';

            let additionalPartiesAppendtypeParty = []; let typeOfPartyString = []; let loop9 = 0;
            for(let i=1;i<=5;i++) {
              additionalPartiesAppendtypeParty[loop9] = (ele.typeOfParty === i) ?  1 : "";
              loop9++;  typeOfPartyString[loop9] = "";
            }
            let additionalPartiesAppendgender = []; let genderString = []; let loop10 = 0;
            for(let i=1;i<=3;i++) {
              additionalPartiesAppendgender[loop10] = (ele.gender === i) ?  1 : "";
              genderString[loop10] = '';  loop10++;
            }

            appendAdditionalArr.push(dateVal,caseNo);
            appendAdditionalArr.push(''); // appendAdditionalArr.push(`"${fileNumber}"`);
            if(officeDetails?.folioNumberActive  === 1)  appendAdditionalArr.push(''); //appendArr.push(`"${folioNumber}"`);
            if(officeDetails.filePageNumberActive  === 1)  appendAdditionalArr.push(''); // appendArr.push(`"${filePageNumber}"`);
            // appendAdditionalArr.push(`"${paymentOfFees}"`,`"${mediator}"`);
            appendAdditionalArr.push('');  appendAdditionalArr.push('');
            appendAdditionalArr.push(...additionalPartycaseOutcome); appendAdditionalArr.push(''); //`"${caseOutcomeOther}"`
            if(officeDetails?.typeOfDisputeActive  === 1)  { appendAdditionalArr.push(...additionalPartyTypeOfDispute);  appendAdditionalArr.push(''); //typeOfDisputeOther
            }
            if(officeDetails?.typeOfDisputeTxtActive  === 1)     appendAdditionalArr.push('');    
            // appendAdditionalArr.push( `"${typeOfDisputeOther}"`,dateOfLastUpdate,nextDeadline,`"${nrOfMeetings}"`) appendAdditionalArr.push('');
            appendAdditionalArr.push(''); appendAdditionalArr.push(''); appendAdditionalArr.push('');
            if(officeDetails.attendanceActive  === 1) { appendAdditionalArr.push(''); appendAdditionalArr.push('');   }
            if(officeDetails.finesActive  === 1)  appendAdditionalArr.push('');    // appendAdditionalArr.push(`"${fines}"`);
            if(officeDetails.enforcementActive  === 1)  appendAdditionalArr.push(...additionalPartyForcement);

            appendAdditionalArr.push('');  //// appendAdditionalArr.push(`"${dateOfNextMeeting}"`);

            if(ele.type === 'reqParty') {
                appendAdditionalArr.push(name,email,address,country,stateOrCounty,telephone, ...additionalPartiesAppendtypeParty,typeOfPartyOther,...additionalPartiesAppendgender,
                  '' , ''  , ''  , '','','',...typeOfPartyString,...genderString,'','',''  , ''  , '' , '' , '','','','' ,'' , '' );
                csv.push(appendAdditionalArr);
            } else {
                appendAdditionalArr.push('','' , ''  , ''  , '','','',...typeOfPartyString,...genderString,'','','','','',
                 name,email,address,country,stateOrCounty,telephone,...additionalPartiesAppendtypeParty,typeOfPartyOther,...additionalPartiesAppendgender,
                '', '', '','','','' ,'' , '' );
               csv.push(appendAdditionalArr);
            }
          });
      }
     return csv;
  }

  downloadLink(workbook,fileName){
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // Download the Excel file
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  loadCasesForReports(officeName) {
    return new Promise<any>((resolve) => {
      this.service.getAllCases(officeName).subscribe((response: officeConfiguration[]) => {
        if (response) {
          resolve(response)
        }
      });
    });
  }

  loadPreCasesForReports(officeName) {
    return new Promise<any>((resolve) => {
      this.service.getAllPrecases(officeName).subscribe((response: officeConfiguration[]) => {
        if (response) {
          resolve(response)
        }
      });
    });
  }

  meetingHEADER(){
    let HEADERS = [
      this.translate.instant('office.meetings.MEETING_DATE'),
      this.translate.instant('office.meetings.MEETING_TIME'),
      this.translate.instant('office.meetings.CLAIMANT'),
      this.translate.instant('office.meetings.RESPONDENT'),
      this.translate.instant('office.meetings.OFFICE_CASE_NUMBER'),
      this.translate.instant('office.meetings.WEBEX_LINK'),
      this.translate.instant('office.meetings.MEETING_NUMBER'),
      this.translate.instant('office.meetings.PASSWORD'),
      this.translate.instant('office.meetings.MEETING_HOST_KEY'),
      this.translate.instant('office.meetings.MEETING_RECORDING_LINK_1'),
      this.translate.instant('office.meetings.MEETING_RECORDING_LINK_2'),
      this.translate.instant('office.meetings.SESSION_NUMBER'),
      this.translate.instant('office.meetings.STATUS'),
      // this.translate.instant('office.meetings.CREATED_BY'),
      this.translate.instant('office.meetings.EMAIL')
    ];
    return HEADERS; 
  }

  appendMetingDetails(element,xl){
    let dateVal = this.convertFormat(element.meetingDate);
    let time = element?.meetingTime;
    let claimant = element.claimant ? element.claimant.trim()  : '';
    let respondent = element.respondent ?  element.respondent.trim() : '';
    let officeCaseNumber = element.officeCaseNumber ? element.officeCaseNumber : '';
    let sessionNumber = element.sessionNumber ? element.sessionNumber : '';
    let webexLink = element.webexLink ? element.webexLink : '';
    let meetingNumber = element.meetingNumber ? element.meetingNumber : '';
    let password = element.password ? element.password : '';
    let meetingHostKey = element.meetingHostKey ? element.meetingHostKey : '';
    let meetingRecordingLink1 = element.meetingRecordingLink1 ? element.meetingRecordingLink1 : '';
    let meetingRecordingLink2 = element.meetingRecordingLink2 ? element.meetingRecordingLink2 : '';
    let status = element.status ? this.translate.instant('office.meetings.' + element.status) : '';
    // let createdBy =  element.createdBy ? element.createdBy : '' ; remove because API response didn't get this value
    let email = element.email ? element.email : '';
    let appendArr = [];
    appendArr = [dateVal,time,claimant,respondent,officeCaseNumber,webexLink,meetingNumber,password,
                 meetingHostKey,meetingRecordingLink1,meetingRecordingLink2,sessionNumber,status,email];
    xl.push(appendArr);

    return XLSX;
  }

  async downloadMeetingReport(meetingData,officeName) {
      let HEADERS = this.meetingHEADER();
      let xl: any = [];
      meetingData.forEach(async element => { xl = await this.appendMetingDetails(element,xl);  });

      const data1 = [{ sheetName: 'Meetings', data: [ HEADERS,...xl] }];
      console.log('data1 --',data1)

     // Create a new workbook
     const workbook = XLSX.utils.book_new();
     //  Iterate over each sheet's data and create worksheets

     data1.forEach((sheetData) => {
       let worksheet = XLSX.utils.aoa_to_sheet(sheetData.data);
       XLSX.utils.book_append_sheet(workbook, worksheet, sheetData.sheetName);
     });

     // Generate the Excel file download link
     this.downloadLink(workbook,'Meetings');

  }
 

  async downloadAllReports(officeName) {
    let casesAllData = await this.loadCasesForReports(officeName);
    let preCasesAllData = await this.loadPreCasesForReports(officeName);
    let meetingData = await this.service.getMeetingDetails(officeName).toPromise();

    let officeDetails: any = [];  officeDetails = await this.getOfficeDetails(officeName);
    let xl1: any = []; let xl2: any = []; let xl3: any = [];

    let HEADERS1 = await this.getHeader(officeName,officeDetails,'case');
    let HEADERS2 = await this.getHeader(officeName,officeDetails,'precase');
    let HEADERS3 = await this.meetingHEADER();

    casesAllData.forEach(async element => {  xl1 = await this.appenDetails(element,officeDetails,xl1,'case');  });
    preCasesAllData.forEach(async element => {  xl2 = await this.appenDetails(element,officeDetails,xl2,'precase'); });
    meetingData.forEach(async element => {  xl3 = await this.appendMetingDetails(element,xl3); });

    const allData = [{ sheetName: 'cases', data: [ HEADERS1,...xl1] },
                     { sheetName: 'preCases', data: [ HEADERS2,...xl2] },
                     { sheetName: 'meetings', data: [ HEADERS3,...xl3] } ];

    console.log('allData --',allData)

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    //  Iterate over each sheet's data and create worksheets

    allData.forEach((sheetData) => {
      let worksheet = XLSX.utils.aoa_to_sheet(sheetData.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetData.sheetName);
    });

    // Generate the Excel file download link
    this.downloadLink(workbook,'allDataReport');
     
  }


  async downloadReports(data,officeName,caseStatus) {
      let officeDetails: any = [];  officeDetails = await this.getOfficeDetails(officeName);
      let HEADERS = await this.getHeader(officeName,officeDetails,caseStatus);
      let xl: any = [];
      data.forEach(async element => {
        xl = await this.appenDetails(element,officeDetails,xl,caseStatus);
      });

       const data1 = [{ sheetName: caseStatus, data: [ HEADERS,...xl] }];
       console.log('data1 --',data1)

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      //  Iterate over each sheet's data and create worksheets

      data1.forEach((sheetData) => {
        let worksheet = XLSX.utils.aoa_to_sheet(sheetData.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetData.sheetName);
      });

      // Generate the Excel file download link
      this.downloadLink(workbook,caseStatus);
     
  }




  async appenDetailsCSV(element,officeDetails,csv,caseStatus) {
    let caseNo = '';
    let dateVal = this.convertFormat(element.dateReceived);
    caseNo = (caseStatus === 'precase') ? element.code : element.officeCaseNumber;
    let fileNumber = (element.fileNumber) ?  element.fileNumber : '';
    let folioNumber = (element.folioNumber) ?  element.folioNumber : '';
    let filePageNumber = (element.filePageNumber) ?  element.filePageNumber : '';
    let paymentOfFees = (element.paymentOfFees) ?  element.paymentOfFees : '';
    let mediator = (element.mediator) ?  element.mediator : '';
    let caseOutcomeOther = (element.caseOutcomeOther) ?  element.caseOutcomeOther : '';

    let caseOutcome = [];
    let additionalPartycaseOutcome = '';
    let loop1 = 0;
    for(let i=1;i<=10;i++) {
    caseOutcome[loop1] = (element.caseOutcome?.includes(i)) ?  1 : "";
    additionalPartycaseOutcome += '' + ",";
    loop1++;
    }
   
    let appendtypeOfDispute = [];
    let additionalPartyTypeOfDispute ='';
    if(officeDetails?.typeOfDisputeActive  === 1) {
      let loop2 = 0;
      for(let i=1;i<=8;i++) {
        appendtypeOfDispute[loop2] = (element.typeOfDispute?.includes(i)) ?  1 : "";
        additionalPartyTypeOfDispute += '' + ",";
        loop2++;
      }
    }
  
    let typeOfDisputeTxt = '';
    if(officeDetails?.typeOfDisputeTxtActive  === 1) {
      typeOfDisputeTxt = (element.typeOfDisputeTxt) ?  element.typeOfDisputeTxt : '';
    }

    let typeOfDisputeOther  = (element.typeOfDisputeOther) ? element.typeOfDisputeOther : '';
    let dateOfLastUpdate  = (element.dateOfLastUpdate) ? this.convertFormat(element.dateOfLastUpdate) : '';
    let nextDeadline  = (element.nextDeadline) ? this.convertFormat(element.nextDeadline) : '';
    let nrOfMeetings  = (element.nrOfMeetings) ? element.nrOfMeetings : '';

    let appendattendance  = (element.attendanceAttended) ? element.attendanceAttended : '';
    let appendNotattendance  = (element.attendanceNotAttended) ? element.attendanceNotAttended : '';

    let  fines = (element.fines) ? element.fines : '';

    let appendenforcement = [];
    let additionalPartyForcement = '';
    let loop4 =0;
    for(let i=1;i<=2;i++) {
      appendenforcement[loop4] = (element.enforcement === i) ?  1 : "";
      additionalPartyForcement += '' + ",";
      loop4++;
    }
    
    let  dateOfNextMeeting = (element.dateOfNextMeeting) ? this.convertFormat(element.dateOfNextMeeting) : '';
    let  nameClaimant = (element.nameClaimant) ? element.nameClaimant : '';
    let  emailClaimant = (element.emailClaimant) ?  element.emailClaimant  : '';
    let  telephoneClaimant = (element.telephoneClaimant) ?   element.telephoneClaimant  : '';
    let  addressClaimant = (element.addressClaimant) ?   element.addressClaimant  : '';
    let  stateOrCountyClaimant  = (element.stateOrCountyClaimant) ?   element.stateOrCountyClaimant  : '';
    let  countryClaimant  = (element.countryClaimant) ?   element.countryClaimant  : '';
    
    let appendtypeClaimant = [];
    let loop5=0;
    for(let i=1;i<=5;i++) {
      appendtypeClaimant[loop5] = (element.typeClaimant === i) ?  1 : "";
      loop5++;
    }

    let typeClaimantOther = (element.typeClaimantOther) ? element.typeClaimantOther  : '';

    let appendclaimantGender = [];
    let loop6=0;
    for(let i=1;i<=3;i++) {
      appendclaimantGender[loop6] = (element.genderClaimant === i) ?  1 : "";
      loop6++;
    }

    let nameClaimantRepBy = (element.nameClaimantRepBy) ? element.nameClaimantRepBy  : '';
    let emailClaimantRepBy = (element.emailClaimantRepBy) ?  element.emailClaimantRepBy  : '';
    let telephoneClaimantRepBy = (element.telephoneClaimantRepBy) ?  element.telephoneClaimantRepBy : '';
    let addressClaimantRepBy = (element.addressClaimantRepBy) ?  element.addressClaimantRepBy : '';
    let countryClaimantRepBy = (element.countryClaimantRepBy) ?  element.countryClaimantRepBy : '';

    let nameRespondent = (element.nameRespondent) ? element.nameRespondent  : '';
    let emailRespondent = (element.emailRespondent) ?  element.emailRespondent  : '';
    let telephoneRespondent = (element.telephoneRespondent) ?   element.telephoneRespondent  : '';
    let addressRespondant = (element.addressRespondent) ?   element.addressRespondent  : '';
    let stateOrCountyRespondent  = (element.stateOrCountyRespondent) ?   element.stateOrCountyRespondent  : '';
    let countryRespondant = (element.countryRespondent) ?   element.countryRespondent  : '';

    let appendtypeRespondent = [];
    let loop7=0;
    for(let i=1;i<=5;i++) {
      appendtypeRespondent[loop7] = (element.typeRespondent === i) ?  1 : "";
      loop7++;
    }
    
    let typeRespondentOther = ( element.typeRespondentOther) ? element.typeRespondentOther : '';

    let appendrespondentGender = [];
    let loop8 =0;
    for(let i=1;i<=3;i++) {
      appendrespondentGender[loop8] = (element.genderRespondent === i) ?  1 : "";
      loop8++;
    }

    let nameRespondentRepBy = ( element.nameRespondentRepBy) ? element.nameRespondentRepBy : '';
    let emailRespondentRepBy = (element.emailRespondentRepBy) ?  element.emailRespondentRepBy : '';
    let addressRespondantRepBy = (element.addressRespondentRepBy) ?   element.addressRespondentRepBy  : '';
    let countryRespondantRepBy = (element.countryRespondentRepBy) ?   element.countryRespondentRepBy  : '';
    let telephoneRespondentRepBy = (element.telephoneRespondentRepBy) ?  element.telephoneRespondentRepBy  : '';

    let status = this.translate.instant('common.'+element.status);

    let disputeDescription = (element.disputeDescription) ?  element.disputeDescription.split("\n")  : '';

    let notes = (element.notes) ?  element.notes.replace(//g, '\'').replace(//g, '\'').replace(//g, '&ndash;').replace(/[]/g, '&mdash;').split("\n")  : '';

    let appendArr: any = [];

    appendArr.push(`"${dateVal}"`,`"${caseNo}"`,`"${fileNumber}"`);

    if(officeDetails?.folioNumberActive  === 1)   appendArr.push(`"${folioNumber}"`);

    if(officeDetails.filePageNumberActive  === 1)   appendArr.push(`"${filePageNumber}"`);

    appendArr.push(`"${paymentOfFees}"` ,`"${mediator}"`,caseOutcome.join(),`"${caseOutcomeOther}"`);

    if(officeDetails?.typeOfDisputeActive  === 1) {
      appendArr.push(appendtypeOfDispute.join());
      appendArr.push(`"${typeOfDisputeOther}"`);
    }

    if(officeDetails?.typeOfDisputeTxtActive  === 1)  appendArr.push(`"${typeOfDisputeTxt}"`);


    appendArr.push(dateOfLastUpdate, nextDeadline, `"${nrOfMeetings}"`);

    if(officeDetails.attendanceActive  === 1) {
      appendArr.push(`"${appendattendance}"`);
      appendArr.push(`"${appendNotattendance}"`);
    }

    if(officeDetails.finesActive  === 1)     appendArr.push(`"${fines}"`);

    if(officeDetails.enforcementActive  === 1) { appendArr.push(appendenforcement.join());  }

      appendArr.push( `"${dateOfNextMeeting}"`,

      `"${nameClaimant}"`  , 
      `"${emailClaimant}"`  ,
      `"${addressClaimant}"` , 
      countryClaimant , 
      `"${stateOrCountyClaimant}"` , 
      `"${telephoneClaimant}"`,
      appendtypeClaimant.join(), 
      `"${typeClaimantOther}"`,
      appendclaimantGender.join(),
     
      `"${nameClaimantRepBy}"`  , 
      emailClaimantRepBy ,
       `"${addressClaimantRepBy}"`,
       countryClaimantRepBy  ,
       `"${telephoneClaimantRepBy}"`,
      
      `"${nameRespondent}"`   , 
      emailRespondent  , 
      `"${addressRespondant}"` , 
      `"${countryRespondant}"`, 
      `"${stateOrCountyRespondent}"` , 
      `"${telephoneRespondent}"`,
      appendtypeRespondent.join(),
      `"${typeRespondentOther}"`,
      appendrespondentGender.join(),
    
      `"${nameRespondentRepBy}"`, 
      `"${emailRespondentRepBy}"`,
      `"${addressRespondantRepBy}"`, 
      `"${countryRespondantRepBy}"`, 
      `"${telephoneRespondentRepBy}"`,

      status , `"${disputeDescription}"` , `"${notes}"` );

      csv.push(appendArr);

      if(element.additionalParties) {
          let addParties = JSON.parse(element.additionalParties);
          addParties.forEach(ele => {
            let appendAdditionalArr:any = [];
            let name = (ele.name) ? ele.name : '';
            let email = (ele.email) ?  ele.email  : '';
            let address = (ele.address) ?   ele.address  : '';
            let country  = (ele.country) ?   ele.country  : '';
            let stateOrCounty = (ele.stateOrCounty) ?   ele.stateOrCounty  : '';
            let telephone = (ele.telephone) ?  ele.telephone  : '';
            let typeOfPartyOther = (ele.typeOfPartyOther) ?  ele.typeOfPartyOther  : '';

            let additionalPartiesAppendtypeParty = [];
            let typeOfPartyString = '';
            let loop9 = 0;
            for(let i=1;i<=5;i++) {
              additionalPartiesAppendtypeParty[loop9] = (ele.typeOfParty === i) ?  1 : "";
              loop9++;
              typeOfPartyString += ''+",";
            }
            let additionalPartiesAppendgender = [];
            let genderString = '';
            let loop10 = 0;
            for(let i=1;i<=3;i++) {
              additionalPartiesAppendgender[loop10] = (ele.gender === i) ?  1 : "";
            genderString += '' + ",";
            loop10++;
            }

            appendAdditionalArr.push(dateVal,`"${caseNo}"`);
            appendAdditionalArr.push(''); // appendAdditionalArr.push(`"${fileNumber}"`);
            if(officeDetails?.folioNumberActive  === 1)  appendAdditionalArr.push(''); //appendArr.push(`"${folioNumber}"`);
            if(officeDetails.filePageNumberActive  === 1)  appendAdditionalArr.push(''); // appendArr.push(`"${filePageNumber}"`);
            // appendAdditionalArr.push(`"${paymentOfFees}"`,`"${mediator}"`);
            appendAdditionalArr.push('');
            appendAdditionalArr.push('');
            appendAdditionalArr.push(additionalPartycaseOutcome.slice(0, -1))
            appendAdditionalArr.push(''); //`"${caseOutcomeOther}"`
            if(officeDetails?.typeOfDisputeActive  === 1)  { 
              appendAdditionalArr.push(additionalPartyTypeOfDispute.slice(0, -1));
              appendAdditionalArr.push(''); //typeOfDisputeOther
            }
            if(officeDetails?.typeOfDisputeTxtActive  === 1)     appendAdditionalArr.push('');    
            // appendAdditionalArr.push( `"${typeOfDisputeOther}"`,dateOfLastUpdate,nextDeadline,`"${nrOfMeetings}"`)
            // appendAdditionalArr.push('');
            appendAdditionalArr.push('');
            appendAdditionalArr.push('');
            appendAdditionalArr.push('');
            if(officeDetails.attendanceActive  === 1) {
              appendAdditionalArr.push('');
              appendAdditionalArr.push(''); 
            }
            if(officeDetails.finesActive  === 1)  appendAdditionalArr.push('');    // appendAdditionalArr.push(`"${fines}"`);
            if(officeDetails.enforcementActive  === 1)  appendAdditionalArr.push(additionalPartyForcement.slice(0, -1));

            appendAdditionalArr.push('');  //// appendAdditionalArr.push(`"${dateOfNextMeeting}"`);

            if(ele.type === 'reqParty') {
                appendAdditionalArr.push( 
                  `"${name}"`,email,`"${address}"`,country,`"${stateOrCounty}"`,`"${telephone}"`,
                  additionalPartiesAppendtypeParty.join(),`"${typeOfPartyOther}"`,additionalPartiesAppendgender.join(),
                  '' , ''  , ''  , '','','',
                  typeOfPartyString.slice(0, -1),genderString.slice(0, -1),
                 '','',''  , ''  , '' , '' , '','','','' ,'' , '' );
                csv.push(appendAdditionalArr);
            } else {
                appendAdditionalArr.push('','' , ''  , ''  , '','','',
                typeOfPartyString.slice(0, -1),genderString.slice(0, -1),'','','','','',
                 `"${name}"`,email,`"${address}"`,country,`"${stateOrCounty}"`,`"${telephone}"`,
                additionalPartiesAppendtypeParty.join(),`"${typeOfPartyOther}"`,additionalPartiesAppendgender.join(),
                '', '', '','','','' ,'' , '' );
               csv.push(appendAdditionalArr);
            }
          });
      }
     return csv;
  }

   async downloadCSV(data,officeName,caseStatus) {
    let officeDetails: any = [];
    officeDetails = await this.getOfficeDetails(officeName);
    this.blocked = true;
    let json = data;
    let HEADERS = await this.getHeader(officeName,officeDetails,caseStatus);
      let csv: any = [];
      json.forEach(async element => {
         csv.push(await this.appenDetailsCSV(element,officeDetails,csv,caseStatus));
      });
      // console.log('CSV -----',csv); 
      csv.join(',')
      csv.unshift(HEADERS.join(',')) // add header column
      csv = "\ufeff" + csv.join('\n');
      download(this.translate.instant('office.cases.csvFilename'), csv.replace(//g, '\'').replace(//g, '\'').replace(//g, '&ndash;').replace(/[]/g, '&mdash;'))
      this.blocked = false;
  }

 }