import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WNotificationBarModule, WTableDirectivesModule ,WInputRadioModule, WDialogModule, WFilterModule, WBlockUiModule, WInputCheckboxOneModule, FFacetModule, WActionBarModule, WArticleModule, WBoxModule, WButtonGroupModule, WButtonModule, WEditPanelModule, WFieldModule, WInputTextareaModule, WInputTextModule, WPageHeaderModule, WPageSectionModule, WSectionModule, WSelectManyModule, WSelectOneModule, WSlotModule, WStepModule, WStickyBarModule, WValidationPanelModule, WViewModule, WViewPanelModule } from '@wipo/w-angular';
import { WInputDateModule, WInputFileModule } from '@wipo/w-angular/primeng';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AdditionalPartyComponent } from './component/additional-party/additional-party.component';
import { CommonModule } from '@angular/common';
import { DebounceClickDirective } from './directives/debounce-click.directive';

@NgModule({
  declarations: [
  AdditionalPartyComponent,
  DebounceClickDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TableModule,
    PaginatorModule,
    FFacetModule,
    WActionBarModule,
    WArticleModule,
    WBoxModule,
    WButtonGroupModule,
    WButtonModule,
    WEditPanelModule,
    WFieldModule,
    WInputTextModule,
    WInputTextareaModule,
    WPageHeaderModule,
    WPageSectionModule,
    WSectionModule,
    WSelectManyModule,
    WSelectOneModule,
    WSlotModule,
    WStepModule,
    WStickyBarModule,
    WViewModule,
    WViewPanelModule,
    WInputFileModule,
    WInputDateModule,
    WValidationPanelModule,
    WInputRadioModule,
    WTableDirectivesModule, 
    WDialogModule,
    WBlockUiModule,
    WInputCheckboxOneModule, WFilterModule,
    TranslateModule,
    WNotificationBarModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TableModule,
    PaginatorModule,
    FFacetModule,
    WActionBarModule,
    WArticleModule,
    WBoxModule,
    WButtonGroupModule,
    WButtonModule,
    WEditPanelModule,
    WFieldModule,
    WInputTextModule,
    WInputTextareaModule,
    WPageHeaderModule,
    WPageSectionModule,
    WSectionModule,
    WSelectManyModule,
    WSelectOneModule,
    WSlotModule,
    WStepModule,
    WStickyBarModule,
    WViewModule,
    WViewPanelModule,
    WInputFileModule,
    WInputDateModule,
    WValidationPanelModule,
    WInputRadioModule,
    WTableDirectivesModule,
    WDialogModule,
    WBlockUiModule,
    WInputCheckboxOneModule, WFilterModule,
    TranslateModule,
    WNotificationBarModule,
    AdditionalPartyComponent,
    DebounceClickDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WcommonModule { }





