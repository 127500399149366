import { ChangeDetectorRef, Component,forwardRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Party,PartyType } from 'src/app/_models/party';
import { UtilsService } from '../../UtilsService';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import customeOptions  from 'src/assets/json/customeOptions.json'


@Component({
  selector: 'app-additional-party',
  templateUrl: './additional-party.component.html',
  styleUrls: ['./additional-party.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdditionalPartyComponent),
      multi: true
    },
  ]
})
export class AdditionalPartyComponent implements OnInit, ControlValueAccessor {
  @Input() editCase:boolean = false;
  @Input() dispCountry:boolean = false;
  @Input() readOnly:boolean = false;
  genderOptions: any[] = [];
  typeOfPartyOptions :any[] = [];
  optionsCountries: any[] = [];
  invalidEmailsValidation = false;
  requestingParty: Party;
  respondingParty: Party;
  additionalParties: Party[] = [];
  editedParty = new Party();
  currentLanguage = 'en';
 // For additional parties validation
  validChar = [];
  validCharActiveFlagValidation = false;
  validName = false;
  validAddress = false;
  validTel = false;
  validEmail = false;
  editAdd = false;
  translateSubscription: Subscription;
  
  constructor(private cdr: ChangeDetectorRef,private utilsService: UtilsService,private translate: TranslateService) { }

  ngOnInit() {
    this.translateSubscription = this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang;
      this.loadOptions();
    });
    this.loadOptions();
  }

  ngOnDestroy() {
    if (this.translateSubscription) { this.translateSubscription.unsubscribe() }
  }

  loadOptions() {
    this.optionsCountries = this.utilsService.loadCountryOption();
    if(this.editCase) {
    this.typeOfPartyOptions = this.utilsService.loadOptions(customeOptions.typeOfPartyOptions);
    this.genderOptions = this.utilsService.loadOptions(customeOptions.genderOptions);
    }
  }

  writeValue(additionalParties: Party[]): void {
    if (this.utilsService.isBlank(additionalParties)) {
      this.additionalParties = new Array();      
    } else {
      this.additionalParties = additionalParties;
    }
  }

  onChange: any = () => {}

  onTouch: any = () => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  

  toggleAddEdit() {
    this.editAdd = !this.editAdd;
  }

  addRespondent() {
    let respondent = new Party();
    respondent.type = PartyType.respondent;
    this.addNewParty(respondent);
    this.invalidEmailsValidation = false;
  }

  addComplainant() {
    let complainant = new Party();
    complainant.type = PartyType.complainant;
    this.addNewParty(complainant);
    this.invalidEmailsValidation = false;
  }

  addNewParty(party: Party) {
    this.toggleAddEdit();
    this.editedParty = party;
    this.editedParty.id = this.additionalParties.length ? this.additionalParties[this.additionalParties.length - 1].id + 1 : 1;
  }

  editParty(party: Party) {
    this.toggleAddEdit();
    this.editedParty = party;
  }

  saveParty() {
    // For additional parties validation 
    this.validCharActiveFlagValidation = false;
    for (const key in this.editedParty) {
      this.validChar[key] = this.utilsService.countChars(this.editedParty[key])
      if(this.validChar[key])  { this.validCharActiveFlagValidation = false;  }
    }
    if(!this.invalidEmailsValidation && !this.validCharActiveFlagValidation) {
      if (this.additionalParties.some(e => e.id === this.editedParty.id)) {
        const index = this.additionalParties.indexOf(this.additionalParties.find(e => e.id === this.editedParty.id));
        this.additionalParties[index] = this.editedParty;
      } else {
        this.additionalParties.push(this.editedParty);
      }
      this.cdr.detectChanges();
      this.toggleAddEdit();
    }
    this.onChange(this.additionalParties);
  
  }

   
  deleteParty(party: Party) {
    const index = this.additionalParties.indexOf(this.additionalParties.find(e => e.id === party.id));
    this.additionalParties.splice(index, 1);
    this.cdr.detectChanges();
  }

  onEmailValid(event) {
    let emailValue = event.editedParty.email;
     if (emailValue) {
       // Validate each email address using a regex
       const EMAIL_REGEXP = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
       // Check if any email address is invalid
       this.invalidEmailsValidation =  (!EMAIL_REGEXP.test(emailValue)) ? true : false;
     }
   }

     // For additional parties validation 
  countCharsVal(event) {
    this.validName    =  this.utilsService.countChars(event.editedParty?.name);
    this.validAddress =  this.utilsService.countChars(event.editedParty?.address); 
    if(!this.dispCountry) { this.validTel  =  this.utilsService.countChars(event.editedParty?.telephone); }
    this.validEmail   =  this.utilsService.countChars(event.editedParty?.email);   
  }

  validate(): boolean {
        return !this.validCharActiveFlagValidation && !this.invalidEmailsValidation;
  }

}
