import { Component, OnInit } from '@angular/core';
import { WipoAuthService } from 'src/app/_services/wipo-auth.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public userAuthenticated = false;
  constructor(private wipoAuthService: WipoAuthService) {
    // this.wipoAuthService.loginChanged
    // .subscribe(userAuthenticated => {
    //   console.log('Authentication status in APPComponent', userAuthenticated);
    //   this.userAuthenticated = userAuthenticated;
    // });

  }

  ngOnInit(): void {
  }

  login() {
    this.wipoAuthService.loginWithUserPassword();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }
}