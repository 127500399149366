export class CountryModel{
    public label: string;
    public value: string;
    public name: string;
  }

  export class customeModel{
    public label: string;
    public value: number;
    public name: string;
  }