import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import decision_tree from "../../_files/decision_tree.json";


@Component({
  selector: 'app-wipo-mcst-include-dispute-resolution',
  templateUrl: './wipo-mcst-include-dispute-resolution.html'
})
export class WipoMcstIncludeDisputeRsltComponent implements OnInit {
  URL: string;
  copyrghtContentDisputeSubmit = false;
  resetBackButton = false;
  adrClausesGenerator = false;
  adrClausesPublicationOthrResource = false;
  copyrghtContentDisputeSubmitBtn = true;
  isDisabledIncludeDispute = false;

  @Input() includeDisputeResolution;
  @Output() newItemEvent = new EventEmitter<boolean>();

  constructor(private fb: UntypedFormBuilder, private router: Router) { }
  copyrghtContentDispute = decision_tree.copyrghtContentDispute;

  ngOnInit(): void {
  }

  includeDisputeResolutionForm = this.fb.group({
    includeDisputes: ['', Validators.required]
  });

  get f() {
    return this.includeDisputeResolutionForm.controls;
  }

  onSubmit() {
    this.copyrghtContentDisputeSubmit = true;
    if (this.includeDisputeResolutionForm.invalid) {
      return;
    }
    else {
      this.copyrghtContentDisputeSubmitBtn = !this.copyrghtContentDisputeSubmitBtn;
      this.isDisabledIncludeDispute = !this.isDisabledIncludeDispute;
      if (this.includeDisputeResolutionForm.controls.includeDisputes.value == "adrClausesGenerator") {
        this.adrClausesGenerator = !this.adrClausesGenerator;
      }
      if (this.includeDisputeResolutionForm.controls.includeDisputes.value == "adrClausesPublicationOthrResource") {
        this.adrClausesPublicationOthrResource = !this.adrClausesPublicationOthrResource;
      }
    }
  }

  wipoIncludeCopyrightPrevBtn() {
    this.resetBackButton = !this.resetBackButton;
    this.newItemEvent.emit(this.resetBackButton);
  }
  wipoADRCalusesBackBtn() {
    this.adrClausesGenerator = !this.adrClausesGenerator;
    this.includeDisputeResolutionForm.reset();
    this.isDisabledIncludeDispute = !this.isDisabledIncludeDispute;
    this.copyrghtContentDisputeSubmit = !this.copyrghtContentDisputeSubmit;
    this.copyrghtContentDisputeSubmitBtn = !this.copyrghtContentDisputeSubmitBtn;
  }
  wipoADRPublicationsBackBtn() {
    this.includeDisputeResolutionForm.reset();
    this.isDisabledIncludeDispute = !this.isDisabledIncludeDispute;
    this.copyrghtContentDisputeSubmitBtn = !this.copyrghtContentDisputeSubmitBtn;
    this.adrClausesPublicationOthrResource = !this.adrClausesPublicationOthrResource;
    this.copyrghtContentDisputeSubmit = !this.copyrghtContentDisputeSubmit;
  }
  wipoAdrClauseGeneratorLink() {
    this.URL = 'https://amc.wipo.int/clause-generator/';
    window.open(this.URL, '_blank');
  }
  wipoAdrPublicationResourceLink() {
    this.URL = 'https://www.wipo.int/amc/en/publications/';
    window.open(this.URL, '_blank');
  }
}