import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { APP_BASE_HREF, DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import { loadNavbar } from './_shared/loader';
import { HttpErrorInterceptor } from './_services/http-error.interceptor';
import { RequestGoodOfficesServicesComponent } from './pages/request-good-offices-services/request-good-offices-services.component';
import { RequestMediationComponent } from './pages/request-mediation/request-mediation.component';
import { UnilateralRequestMediationComponent } from './pages/unilateral-request-mediation/unilateral-request-mediation.component';
import { RequestArbitrationComponent } from './pages/request-arbitration/request-arbitration.component';
import { ExpeditedArbitrationComponent } from './pages/expedited-arbitration/expedited-arbitration.component';
import { AdrYoungRegistrationComponent } from './pages/adryoung/registration.component';
import { WipoMcstDecisionTreeComponent } from './pages/wipo-mcst-decision-tree/wipo-mcst-decision-tree.component';
import { WipoMcstIncludeDisputeRsltComponent } from './pages/wipo-mcst-decision-tree/wipo-mcst-include-dispute-resolution';
import { WipoMcstIncludeDisputeRelatedContractComponent } from './pages/wipo-mcst-decision-tree/wipo-mcst-dispute-related-contract';
import { LoaderComponent } from './loader/loader.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HomeComponent } from './pages/home/home.component';
import { WipoAuthService } from './_services/wipo-auth.service';
import { LoaderService } from './_services/loader.service';
import { ContractComponent } from './pages/wipo-mcst-decision-tree/contract/contract.component';

import localeEs from '@angular/common/locales/es';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader'
import { registerLocale } from '@wipo/w-angular/primeng';
import { WcommonModule } from './_shared/wcommon.module';
import { AuthInterceptor } from './_services/auth.interceptor';

registerLocale(localeEs);


@NgModule({
  declarations: [
    AppComponent,
    RequestGoodOfficesServicesComponent,
    RequestMediationComponent,
    UnilateralRequestMediationComponent,
    RequestArbitrationComponent,
    ExpeditedArbitrationComponent,
    AdrYoungRegistrationComponent,
    WipoMcstDecisionTreeComponent,
    WipoMcstIncludeDisputeRsltComponent,
    WipoMcstIncludeDisputeRelatedContractComponent,
    LoaderComponent,
    HomeComponent,
    ContractComponent,
    PageNotFoundComponent,

  ],
  imports: [
   
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    WcommonModule,
    
 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'http://local.wipo.int:4400/api/v1',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1',
          'https://www3.wipo.int/amc-forms-rest/api/v1',

          'http://local.wipo.int:4400/api/v1/internal',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal',

          'http://local.wipo.int:4400/api/v1/internal/dcti/request',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/dcti/request',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/dcti/request',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/dcti/request',

          'http://local.wipo.int:4400/api/v1/internal/dcti/answer',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/dcti/answer',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/dcti/answer',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/dcti/answer',

          'http://local.wipo.int:4400/api/v1/internal/dcti/statement-of-acceptance',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/dcti/statement-of-acceptance',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/dcti/statement-of-acceptance',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/dcti/statement-of-acceptance',

          'http://local.wipo.int:4400/api/v1/internal/meetings',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/meetings',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/meetings',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/meetings',

          'http://local.wipo.int:4400/api/v1/internal/indautor',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/indautor',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/indautor',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/indautor',

          'http://local.wipo.int:4400/api/v1/internal/dnda',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/internal/dnda',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/internal/dnda',
          'https://www3.wipo.int/amc-forms-rest/api/v1/internal/dnda',

          'http://local.wipo.int:4400/api/v1/offices',
          'https://www3dev.wipo.int/amc-forms-rest/api/v1/offices',
          'https://www3-test.wipo.int/amc-forms-rest/api/v1/offices',
          'https://www3.wipo.int/amc-forms-rest/api/v1/offices',

          'http://local.wipo.int:4400/api/offices',
          'https://www3dev.wipo.int/amc-forms-rest/api/offices',
          'https://www3-test.wipo.int/amc-forms-rest/api/offices',
          'https://www3.wipo.int/amc-forms-rest/api/offices',

          'http://local.wipo.int:4400/indautor',
          'https://www3dev.wipo.int/amc-forms-rest/indautor',
          'https://www3-test.wipo.int/amc-forms-rest/indautor',
          'https://www3.wipo.int/amc-forms-rest/indautor',

          'http://local.wipo.int:4400/dnda',
          'https://www3dev.wipo.int/amc-forms-rest/dnda',
          'https://www3-test.wipo.int/amc-forms-rest/dnda',
          'https://www3.wipo.int/amc-forms-rest/dnda'
        ],
        sendAccessToken: true
      }
    })

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
     {
       provide: APP_BASE_HREF,
       useValue: environment.contextIsRoot ? '/' : '/' + (window.location.pathname.split('/')[1] || '')
     }, 
     { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true },   
     {
      provide: APP_INITIALIZER,
      // Intializing authentication service at the time of application start-up
      useFactory: (wipoAuthService: WipoAuthService) => () => wipoAuthService.initialize(),
      deps: [WipoAuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => loadNavbar,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      deps: [LoaderService],
      multi: true
    },
    DatePipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/i18n/', suffix: '.json' },
    { prefix: 'assets/i18n/offices/', suffix: '.json' },
  ])
}



