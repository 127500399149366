<div *ngIf="includeDisputeResolution">
  <form [formGroup]="includeDisputeResolutionForm" (ngSubmit)="onSubmit()">
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="Select one option to given below" [options]="copyrghtContentDispute" [labelOnTop]="true"
            [required]="true" [disabled]="isDisabledIncludeDispute" formControlName="includeDisputes">
            <f-facet name="error" *ngIf="copyrghtContentDisputeSubmit && f.includeDisputes.errors">Please select one
              option</f-facet>
          </w-input-radio>
        </w-slot>
      </w-section>
      <f-facet *ngIf="copyrghtContentDisputeSubmitBtn" name="actions">
        <button w-button [isDefault]="true" [size]="'small'" (click)="wipoIncludeCopyrightPrevBtn()"
          [buttonType]="'text'">Back</button>
        <button w-button [isDefault]="true" [size]="'small'" type="submit" [buttonType]="'text'">Continue</button>
      </f-facet>
    </w-edit-panel>
  </form>
</div>

<!-- link for Wipo ADR clauses and clauses generator   -->
<div *ngIf="adrClausesGenerator">
  <w-step collapsible="false" reviewMode=true>
    <p facet-step-info>
      Please go to WIPO Alternative Dispute Resolution (ADR) Clauses and Clauses Generator.
    </p>
    <div facet-step-actions style="float:right">
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoADRCalusesBackBtn()"
        [buttonType]="'text'">Back</button>
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoAdrClauseGeneratorLink()"
        [buttonType]="'text'">Go to WIPO ADR</button>
    </div>
  </w-step>
</div>
<!-- link for Wipo ADR publication and other resources -->
<div *ngIf="adrClausesPublicationOthrResource">
  <w-step collapsible="false" reviewMode=true>
    <p facet-step-info>
      Please go to WIPO Alternative Dispute Resolution (ADR) publications and Other resources.
    </p>
    <div facet-step-actions style="float:right">
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoADRPublicationsBackBtn()"
        [buttonType]="'text'">Back</button>
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoAdrPublicationResourceLink()" [buttonType]="'text'">Go to
        WIPO ADR</button>
    </div>
  </w-step>
</div>