<w-block-ui [blocked]="blocked" [title]="'processing' | translate" [text]="'please_wait' | translate"></w-block-ui>

<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
  <w-page-header [title]="'registration.pageTitle' | translate" [disableUppercase]="true" [showPortfolio]="false">
  </w-page-header>
  <w-step>
    <p facet-step-info [innerHTML]="'registration.description' | translate "></p>
  </w-step>

  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-text [label]="'registration.FIRST_NAME' | translate" [labelOnTop]="true" [required]="true"
          formControlName="firstname">
          <f-facet name="error" *ngIf="submitted && f.firstname.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.firstname.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text [label]="'registration.LAST_NAME' | translate" [labelOnTop]="true" [required]="true"
          formControlName="lastname">
          <f-facet name="error" *ngIf="submitted && f.lastname.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.lastname.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text [label]="'registration.JOB_TITLE' | translate" [labelOnTop]="true" [required]="true"
          formControlName="jobtitle">
          <f-facet name="error" *ngIf="submitted && f.jobtitle.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.jobtitle.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>

        <w-input-text [label]="'registration.ORGANIZATION' | translate" [labelOnTop]="true" [required]="true"
          formControlName="organization">
          <f-facet name="error" *ngIf="submitted && f.organization.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.organization.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>

        <!-- <w-input-text
      [label]="'registration.DATE_OF_BIRTH' | translate"
      [labelOnTop]="true"
      [required]="true"
      formControlName="dateofbirth"
    >
    <f-facet name="error" *ngIf="submitted && f.dateofbirth.errors">{{'validationWarning' | translate }}</f-facet>
  </w-input-text> -->

        <w-input-date [label]="'registration.DATE_OF_BIRTH' | translate" [labelOnTop]="true" [required]="true"
          formControlName="dateofbirth" dateFormat="dd/mm/yy">
          <f-facet name="error" *ngIf="submitted && f.dateofbirth.errors">{{'validationWarning' | translate }}</f-facet>
        </w-input-date>

        <w-input-text [label]="'registration.CITY' | translate" [labelOnTop]="true" [required]="true"
          formControlName="city">
          <f-facet name="error" *ngIf="submitted && f.city.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.city.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>

        <w-select-one [label]="'registration.COUNTRY' | translate" [labelOnTop]="true" [required]="true"
          formControlName="country" [options]="countryOptions">
          <f-facet name="error" *ngIf="submitted && f.country.errors">{{'validationWarning' | translate }}</f-facet>
        </w-select-one>

        <w-input-text [label]="'registration.EMAIL' | translate" [labelOnTop]="true" [required]="true"
          formControlName="emailc" [type]="'email'">
          <f-facet name="error" *ngIf="submitted && f.emailc.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="f.emailc.errors?.email">{{'validationEmail' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailc.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>


    <w-section>
      <w-slot>
        <w-input-text [label]="'registration.ONLINE_PROFILE' | translate" [labelOnTop]="true"
          formControlName="onlineprofile">
          <f-facet name="error" *ngIf="submitted && f.onlineprofile.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>

    <w-section>
      <f-facet name="header">{{'registration.ATTACH_PROFLE' | translate }}</f-facet>
      <w-slot>
        <w-input-file [isLarge]="false" [auto]="false" [multiple]="false" [sizeLimit]="10000000"
          [labelSelect]="'registration.ADD_DOCUMENT' | translate"
          [invalidFileSizeMessageSummary]="'ValidFileSize' | translate" [showUploadButton]="false" [auto]="false"
          (wSelected)="onFileChange($event)" formControlName="documents">
        </w-input-file>
      </w-slot>
    </w-section>

    <w-section>
      <w-slot>
        <w-input-textarea [label]="'registration.ABOUT_WIPO' | translate" [placeholder]="''" formControlName="how">
          <f-facet name="error" *ngIf="submitted && f.how.errors?.maxlength">{{'textLengthWarning' | translate: { maxLength: 255 } }</f-facet>
        </w-input-textarea>
      </w-slot>
    </w-section>

    <!--<w-section>
      <w-slot>
        <w-input-text
          label="Verification"
          [labelOnTop]="true"
          [required]="true"
          formControlName="verification"
        >
        <f-facet name="error" *ngIf="submitted && f.verification.errors">This field cannot be empty</f-facet>
      </w-input-text>
      </w-slot>
    </w-section>-->

  </w-edit-panel>

  <w-view [center]="false" [textPosition]="'start'">

    <f-facet name="description">
      <re-captcha formControlName="g_recaptcha_response" siteKey="{{siteKey}}"></re-captcha>
    </f-facet>
    <f-facet name="footer" *ngIf="submitted && f.g_recaptcha_response.errors?.required">
      <w-validation-panel [type]="'error'" [summary]="'validationCaptch' | translate"></w-validation-panel>
    </f-facet>
  </w-view>

</form>

<f-facet name="step-info">
<w-page-header [disableUppercase]="true" [showPortfolio]="false">
  <f-facet name="actions">
    <button w-button [isDefault]="true" [isSecondary]="false" [disabled]="false" [buttonType]="'text'" 
      type="submit" (click)="onSubmit()">
      {{ 'submitRequest' | translate }}
    </button>
  </f-facet>
</w-page-header>
<br><br>
</f-facet>

<w-dialog *ngIf="isOpen" [style]="style">
  <w-step [title]="'registration.alerTitle' | translate" [reviewMode]="true">
    <div facet-step-info [innerHTML]="'registration.alerText' | translate"></div>
    <button w-button (click)="closeDialogbox()" facet-step-actions>OK</button>
  </w-step>
</w-dialog>