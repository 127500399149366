<div *ngIf="disputeRelatedContractFormStatus">
  <form [formGroup]="disputeRelatedContractForm" (ngSubmit)="onSubmit()">
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio label="Select one option to given below" [options]="disputeRelatedContractOptions"
            [labelOnTop]="true" [required]="true" [disabled]="isDisabledDisputeContract"
            formControlName="disputeContract">
            <f-facet name="error" *ngIf="disputeRelatedContractSubmit && f.disputeContract.errors">Please select one
              option</f-facet>
          </w-input-radio>
        </w-slot>
      </w-section>
      <f-facet *ngIf="disputeRelatedContractSubmitBtn" name="actions">
        <button w-button [isDefault]="true" [size]="'small'" (click)="wipoDisputeContractPrevBtn()"
          [buttonType]="'text'">Back</button>
        <button w-button [isDefault]="true" [size]="'small'" type="submit" [buttonType]="'text'">Continue</button>
      </f-facet>
    </w-edit-panel>
  </form>
</div>

<!-- Wipo Mediation clauses Contract link   -->
<div *ngIf="linkWipoMediactionClauseContract">
  <w-step collapsible="false" reviewMode=true>
    <p facet-step-info>
      Please go to WIPO Alternative Dispute Resolution (ADR) and File Request for Wipo Mediation.
    </p>
    <div facet-step-actions style="float:right">
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoMediactionClauseContractBackBtn()"
        [buttonType]="'text'">Back</button>
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoRequestMediationLinkNext()"
        [buttonType]="'text'">Request For Wipo Mediation</button>
    </div>
  </w-step>
</div>
<!-- Invite Other party to participate in Wipo Mediation -->
<div *ngIf="linkNoWipoMediactionClauseContract">
  <w-step collapsible="false" reviewMode=true>
    <p facet-step-info>
      Please go to WIPO Alternative Dispute Resolution (ADR) and Invite the other party to participate in Wipo
      Mediation.
    </p>
    <div facet-step-actions style="float:right">
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoNoMediactionClauseContractBackBtn()"
        [buttonType]="'text'">Back</button>
      <button w-button [isDefault]="true" [size]="'small'" (click)="wipoRequestMediationLinkNext()"
        [buttonType]="'text'">Request For Wipo Mediation</button>
    </div>
  </w-step>
</div>