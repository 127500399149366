<app-loader></app-loader>
<div dir="{{ 'direction' | translate}}">

  <wipo-navbar *ngIf="isROKMCST()" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey' [applicationLink]='appLink'
    [language]="languageService.currentLanguage" languageOptions='[{ "code": "en"},{ "code": "ko"}]'
    applicationName="{{ 'navbar.isMCSTappName' | translate: {value: navbarTitle} }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory'>
  </wipo-navbar>

  <wipo-navbar *ngIf="isDomain()" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey' [applicationLink]='appLink'
    [language]="languageService.currentLanguage" languageOptions='[{ "code": "en"}]'
    applicationName="{{ 'navbar.isDomainappName' | translate }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory'>
  </wipo-navbar>

  <wipo-navbar *ngIf="isPrivate() && officeLoad" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey' [applicationLink]='appLink'
    [language]="languageService.currentLanguage" [languageOptions]='privateOfficeLanguage'
    applicationName="{{ navbarAppname | translate }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory'>
  </wipo-navbar>

  <wipo-navbar *ngIf="isADR() || isADRYoung() || isPageNotFound()" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey'
    [applicationLink]='appLink' [language]="languageService.currentLanguage"
    languageOptions='[{ "code": "en"}, {"code": "es"}, {"code": "fr"}, {"code": "ru"}, {"code": "zh"}, {"code": "ar"}]'
    applicationName="{{ 'navbar.appname' | translate: {value: navbarTitle} }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory'>
  </wipo-navbar>

  <wipo-navbar *ngIf="!isPrivate() && isCase()" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey'
    [applicationLink]='appLink' [language]="languageService.currentLanguage" [languageOptions]='officeCaseLanguage'
    applicationName="{{ 'navbar.appname' | translate: {value: navbarTitle} }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory'>
  </wipo-navbar>


  <wipo-navbar *ngIf="isDCTI()" [appId]='AMC_FORMS_APP_ID' [pageKey]='pageKey' [applicationLink]='appLink'
    [language]="languageService.currentLanguage" languageOptions='[{ "code": "en"}, {"code": "es"}]'
    applicationName="{{ 'navbar.appname' | translate: {value: navbarTitle} }}" [helpOptions]='contactUsOptions'
    [applicationCategory]='appCategory' [customBreadcrumbs]='DCTIBreadcrumb'>

  </wipo-navbar>

  <step>
    <f-facet name="step-info">
      <w-sticky-bar [sticky]="sticky" *ngIf="isADR()">
        <w-sticky-bar-link label="{{'ipportalDefault.mediation.pageName' | translate}}"
          [routerLink]="linkadrMediation"></w-sticky-bar-link>
        <w-sticky-bar-link label="{{'ipportalDefault.unilateralRequest.pageName' | translate}}"
          [routerLink]="linkadruniteralL"></w-sticky-bar-link>
        <w-sticky-bar-link label="{{'ipportalDefault.requestArbitration.pageName' | translate}}"
          [routerLink]="linkadrarbitration"></w-sticky-bar-link>
        <w-sticky-bar-link label="{{'ipportalDefault.expeditedArbitration.pageName' | translate}}"
          [routerLink]="linkadrexpedited"></w-sticky-bar-link>
        <w-sticky-bar-link label="{{'ipportalDefault.requestForGoodOffices.pageName' | translate}}"
          [routerLink]="linkadrgoodOffice"></w-sticky-bar-link>
      </w-sticky-bar>

      <w-sticky-bar [sticky]="sticky" *ngIf="isROKMCST()">
        <w-sticky-bar-link [label]="'mcstForm.tree.pageName' | translate"
          [routerLink]="linkrokMCST"></w-sticky-bar-link>
        <w-sticky-bar-link [label]="'mcstForm.mediation.pageName' | translate"
          [routerLink]="linkMediation"></w-sticky-bar-link>
        <w-sticky-bar-link [label]="'mcstForm.unilateralRequest.pageName' | translate"
          [routerLink]="linkUnilateral"></w-sticky-bar-link>
        <w-sticky-bar-link [label]="'mcstForm.requestForGoodOffices.pageName' | translate"
          [routerLink]="linkgoodOffices"></w-sticky-bar-link>
      </w-sticky-bar>
    </f-facet>
  </step>

  <router-outlet></router-outlet>

</div>