<w-step title="{{'additionalParties' | translate}}"> 
    <f-facet name="step-content-top" >
      <w-button-group *ngIf="!readOnly">
        <button w-button [size]="'small'" (click)="addComplainant()" [buttonType]="'text'">{{'addReqParty' | translate}}</button>
        <button w-button [size]="'small'" (click)="addRespondent()" [buttonType]="'text'">{{'addResParty' | translate}}</button>
      </w-button-group>
  
      <ng-container *ngIf="additionalParties.length > 0">
        <w-view-panel [reviewMode]="false" [ignoreReviewMode]="false" [disabled]="false" [highlighted]="false"
          [folded]="true" *ngFor="let item of additionalParties">
          <f-facet name="primary-action">
            <button w-button [label]="'%1'" [size]="'medium'" (click)="editParty(item)" [buttonType]="'edit'"></button>
            <button *ngIf="!readOnly"  w-button [label]="'%1'" [size]="'medium'" (click)="deleteParty(item)" [buttonType]="'delete'"></button>
          </f-facet>
          <f-facet name="section-unfolded">
            <w-section>
              <w-slot>
                <w-field label="{{'name' | translate}}"> {{item.name}} </w-field>
                <w-field label="{{'country' | translate}}" >  {{item.country}}   </w-field>
                <w-field label="{{'address' | translate}}">  {{item.address}}   </w-field>
              </w-slot>
              <w-slot>
                <w-field label="{{'party' | translate}}"> {{item.type | translate}}  </w-field>
                <w-field label="{{'email' | translate}}"> {{item.email}} </w-field>
                <w-field label="{{'tel' | translate}}"> {{item.telephone}} </w-field>
              </w-slot>
              <w-slot *ngIf="this.editCase">
                <w-select-one [label]="'common.genderClaimant' | translate" [labelOnTop]="true" [disabled]='true' [isReadOnly]='true' [(ngModel)]="item.gender" [options]="genderOptions"> </w-select-one>
                <w-select-one [label]="'common.typeClaimant' | translate" [labelOnTop]="true" [disabled]='true' [isReadOnly]='true' [(ngModel)]="item.typeOfParty" [options]="typeOfPartyOptions"> </w-select-one>
              </w-slot>
            </w-section>
          </f-facet>
          <f-facet name="section-folded">
            <w-section>
              <w-slot>
                <w-field label="{{'name' | translate}}">   {{item.name}} </w-field>
              </w-slot>
              <w-slot>
                <w-field label="{{'party' | translate}}"> {{item.type | translate}} </w-field>
              </w-slot>
            </w-section>
          </f-facet>
        </w-view-panel>
      </ng-container>
  
      <w-edit-panel [hidden]="!editAdd">
        <f-facet name="actions">
          <button w-button (click)="toggleAddEdit()">{{'cancel'|translate}}</button>
          <button *ngIf="!readOnly" w-button [isDefault]="true" (click)="saveParty()">{{'save'|translate}}</button>
        </f-facet>
        <w-section>
          <w-slot>  
            <w-input-text *ngIf="readOnly" [disabled]='true' [isReadOnly]='true' label="{{'name' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.name" name="name" (wKeyup)="countCharsVal(this)">
              <f-facet name="error" *ngIf="validName">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>
            <w-input-text *ngIf="!readOnly" label="{{'name' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.name" name="name" (wKeyup)="countCharsVal(this)">
              <f-facet name="error" *ngIf="validName">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>
            <w-input-text *ngIf="readOnly" [disabled]='true' [isReadOnly]='true' label="{{'address' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.address" name="address" (wKeyup)="countCharsVal(this)" >
              <f-facet name="error" *ngIf="validAddress">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>
            <w-input-text *ngIf="!readOnly" label="{{'address' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.address" name="address" (wKeyup)="countCharsVal(this)" >
              <f-facet name="error" *ngIf="validAddress">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>

            <w-input-text *ngIf="readOnly && this.editCase" [disabled]='true' [isReadOnly]='true' label="{{'common.stateOrCountyClaimantRepBy' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.stateOrCounty"
             name="stateOrCounty" >  </w-input-text>
             <w-input-text *ngIf="!readOnly && this.editCase"  label="{{'common.stateOrCountyClaimantRepBy' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.stateOrCounty"
             name="stateOrCounty" >  </w-input-text>

            <w-input-text *ngIf="readOnly" [disabled]='true' [isReadOnly]='true' label="{{'tel' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.telephone"  name="telephone" (wKeyup)="countCharsVal(this)" >
              <f-facet name="error" *ngIf="validTel">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
             </w-input-text>

             <w-input-text *ngIf="!readOnly" label="{{'tel' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.telephone"  name="telephone" (wKeyup)="countCharsVal(this)" >
              <f-facet name="error" *ngIf="validTel">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
             </w-input-text>

             <w-select-one *ngIf="readOnly && this.dispCountry" [disabled]='true' [isReadOnly]='true' label="{{'country' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.country" [options]="optionsCountries" ></w-select-one>
             <w-select-one *ngIf="!readOnly && this.dispCountry" label="{{'country' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.country" [options]="optionsCountries" ></w-select-one>
           
             <w-input-text *ngIf="readOnly" [disabled]='true' [isReadOnly]='true' label="{{'email' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.email" (wKeyup)="onEmailValid(this)" (wKeyup)="countCharsVal(this)"  name="email"  [type]="'email'">
              <f-facet name="error" *ngIf="invalidEmailsValidation">{{'validationEmail' | translate  }}<br></f-facet>
              <f-facet name="error" *ngIf="validEmail">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>
            <w-input-text *ngIf="!readOnly" label="{{'email' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.email" (wKeyup)="onEmailValid(this)" (wKeyup)="countCharsVal(this)"  name="email"  [type]="'email'">
              <f-facet name="error" *ngIf="invalidEmailsValidation">{{'validationEmail' | translate  }}<br></f-facet>
              <f-facet name="error" *ngIf="validEmail">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
            </w-input-text>

            <w-select-one *ngIf="readOnly && this.editCase" [disabled]='true' [isReadOnly]='true' [label]="'common.typeClaimant' | translate" [labelOnTop]="true" [(ngModel)]="editedParty.typeOfParty"
              [options]="typeOfPartyOptions" > </w-select-one>
            <w-select-one *ngIf="!readOnly && this.editCase"  [label]="'common.typeClaimant' | translate" [labelOnTop]="true" [(ngModel)]="editedParty.typeOfParty"
            [options]="typeOfPartyOptions" > </w-select-one>

            <w-input-text *ngIf="readOnly && this.editCase" [disabled]='true' [isReadOnly]='true' label="{{'common.typeClaimantOther' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.typeOfPartyOther"
            name="typeOfPartyOther" >  </w-input-text>
            <w-input-text *ngIf="!readOnly && this.editCase" label="{{'common.typeClaimantOther' | translate}}" [labelOnTop]="true" [(ngModel)]="editedParty.typeOfPartyOther"
            name="typeOfPartyOther" >  </w-input-text>


            <w-select-one *ngIf="readOnly && this.editCase" [disabled]='true' [isReadOnly]='true'  [label]="'common.genderClaimant' | translate" [labelOnTop]="true" [(ngModel)]="editedParty.gender"
             [options]="genderOptions" >
            </w-select-one>
            <w-select-one *ngIf="!readOnly && this.editCase"  [label]="'common.genderClaimant' | translate" [labelOnTop]="true" [(ngModel)]="editedParty.gender"
            [options]="genderOptions" >
           </w-select-one>


          </w-slot>
        </w-section>
      </w-edit-panel>
  
    </f-facet>
  </w-step>
