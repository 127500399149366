import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder} from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Party} from 'src/app/_models/party';
import { UploadService } from 'src/app/_services/upload.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/_services/language.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/_shared/UtilsService';
import { AdditionalPartyComponent } from 'src/app/_shared/component/additional-party/additional-party.component';


@Component({
  selector: 'app-unilateral-request-mediation',
  templateUrl: './unilateral-request-mediation.component.html',
  styleUrls: ['./unilateral-request-mediation.component.scss']
})
export class UnilateralRequestMediationComponent implements OnInit {
  @ViewChild('additionalPartyForm', {static: false}) private additionalPartyForm: AdditionalPartyComponent;
  siteKey = environment.recaptchaSiteKey;
  AMC_LANGUAGE_COOKIE = 'amc_language'; // WIPO language standard cookie name
  currentLanguage = 'en';
  requestingParty: Party;
  respondingParty: Party;
  additionalParties: Party[] = [];
  editedParty = new Party();
  editAdd = false;
  submitted = false;
  isROKMCST = false;
  pageTitle: string;
  collaboration = 'NONE';
  blocked: boolean = false;
  filename: string;
  translateSubscription: Subscription;
  isOpen: boolean = false;
  invalidEmails = false;



  requestForm = this.fb.group({
    nameComplainant: ['', [Validators.required, Validators.maxLength(255)]],
    addressComplainant: ['', [Validators.required, Validators.maxLength(255)]],
    telephoneComplainant: ['', Validators.maxLength(255)],
    emailComplainant: ['', [Validators.required, this.utilsService.validateEmail, Validators.maxLength(255)]],
    nameRespondent: ['', [Validators.required, Validators.maxLength(255)]],
    addressRespondent: ['', Validators.maxLength(255)],
    telephoneRespondent: ['', Validators.maxLength(255)],
    emailRespondent: ['', [Validators.required, this.utilsService.validateEmail, Validators.maxLength(255)]],
    representedByRespondent: ['', Validators.maxLength(255)],
    addressRepresentativeRespondent: ['', Validators.maxLength(255)],
    telephoneRepresentativeRespondent: ['', Validators.maxLength(255)],
    emailRepresentativeRespondent: ['', [this.utilsService.validateEmail, Validators.maxLength(255)]],
    representedByComplainant: ['', Validators.maxLength(255)],
    addressRepresentativeComplainant: ['', Validators.maxLength(255)],
    telephoneRepresentativeComplainant: ['', Validators.maxLength(255)],
    emailRepresentativeComplainant: ['', [this.utilsService.validateEmail, Validators.maxLength(255)]]
  });

  requestOtherForm = this.fb.group({
    disputeDescription: ['', [Validators.required, this.utilsService.customWordCountValidator, Validators.maxLength(18000)]],
    signature: ['', [Validators.required, Validators.maxLength(255)]],
    attachments: null,
    g_recaptcha_response: [null, Validators.required]
  });

  

  constructor(private cdr: ChangeDetectorRef, private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private uploadService: UploadService,
    private activatedroute: ActivatedRoute, private translate: TranslateService, public languageService: LanguageService, private cookieService: CookieService) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {
      if(data.collaboration) {
      this.collaboration = data.collaboration;
      }
    })

    this.translateSubscription = this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang
    });

  }


  onFileChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.filename = ': ' + file.name;
      this.requestOtherForm.get('attachments').setValue(file);
    }
  }

  get f() { return this.requestForm.controls; }

  get g() { return this.requestOtherForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.translate.currentLang) {
      this.currentLanguage = this.translate.currentLang
    } else {
      this.currentLanguage = 'en'
    }
    if (this.requestForm.invalid || this.requestOtherForm.invalid) {
      return;
    }

    if(!this.additionalPartyForm.validate()) { return; }
    
    this.blocked = true;
    let myFormData = new FormData();
    Object.keys(this.requestForm.controls).forEach((key) => {
      if (this.requestForm.value[key] === null) {
        myFormData.append(key, '');
      } else {
        myFormData.append(key, this.requestForm.value[key]);
      }
    });

    Object.keys(this.requestOtherForm.controls).forEach((key) => {
      if (this.requestForm.value[key] === null) {
        myFormData.append(key, '');
      } else {
        myFormData.append(key, this.requestOtherForm.value[key]);
      }
    });

    this.currentLanguage = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);
    myFormData.append('additionalParties', JSON.stringify(this.additionalParties));
    myFormData.append('collaboration', this.collaboration);
    myFormData.append('language', this.currentLanguage);

    this.uploadService.submitUnilateralMediationForm(myFormData).subscribe(
      response => {
        if (response instanceof HttpResponse) {
          if (response.status === 201) {
            this.blocked = false;
            this.isOpen = true;
          } else {
            this.blocked = false;
          }
        }
      }, err => {
        //  alert("Error caught at Subscriber " + err)
        this.blocked = false;
      }, () => { this.blocked = false; });
  }

  removeEle() {
    let elements = document.getElementsByClassName('b-input__multi-select--badge ng-star-inserted');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  closeDialogbox() {
    this.isOpen = false;
    this.submitted = false;
    this.removeEle();
    this.additionalParties = [];
    this.requestForm.reset();
    this.requestOtherForm.reset();
  }

}
