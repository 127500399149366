<w-block-ui [blocked]="blocked" [title]="'processing' | translate" [text]="'please_wait' | translate"></w-block-ui>

<form [formGroup]="requestForm" (ngSubmit)="onSubmit()">

  <w-page-header [title]="'ipportalDefault.requestForGoodOffices.pageTitle' | translate" [disableUppercase]="true"
    [showPortfolio]="false"></w-page-header>
  <w-step>
    <p facet-step-info [innerHTML]="'ipportalDefault.requestForGoodOffices.pageDesc' | translate"></p>
  </w-step>
  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-text label="{{'requestingPartyName' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="nameComplainant">
          <f-facet name="error" *ngIf="submitted && f.nameComplainant.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.nameComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="addressComplainant">
          <f-facet name="error" *ngIf="submitted && f.addressComplainant.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.addressComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true"
          formControlName="telephoneComplainant">
          <f-facet name="error" *ngIf="submitted && f.telephoneComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="emailComplainant" [type]="'email'">
          <f-facet name="error" *ngIf="submitted && f.emailComplainant.errors?.required">{{'validationWarning' |
            translate }}
          </f-facet>
          <f-facet name="error" *ngIf="f.emailComplainant.errors?.email">{{'validationEmail' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailComplainant.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
      <w-slot>
        <w-input-text label="{{'respondingByName' | translate}}" [labelOnTop]="true" formControlName="nameRespondent">
          <f-facet name="error" *ngIf="submitted && f.nameRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'address' | translate}}" [labelOnTop]="true"
          formControlName="addressRespondent">
          <f-facet name="error" *ngIf="submitted && f.addressRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'tel' | translate}}" [labelOnTop]="true"
          formControlName="telephoneRespondent">
          <f-facet name="error" *ngIf="submitted && f.telephoneRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
        <w-input-text label="{{'email' | translate}}" [labelOnTop]="true" formControlName="emailRespondent"
          [type]="'email'">
          <f-facet name="error" *ngIf="f.emailRespondent.errors?.email">
            {{'validationEmail' | translate }}
          </f-facet>
          <f-facet name="error" *ngIf="submitted && f.emailRespondent.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>
  </w-edit-panel>

</form>



<app-additional-party #additionalPartyForm  [(ngModel)]="additionalParties" ></app-additional-party>
<br><br>

<form [formGroup]="requestOtherForm" (ngSubmit)="onSubmit()">


  <w-edit-panel>
    <w-section>
      <w-slot>
        <w-input-textarea label="{{'disputeMessage' | translate}}" [required]="true" [placeholder]="''"
          formControlName="disputeDescription">
          <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.required">{{'validationWarning' | translate
            }}</f-facet>
          <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.maxlength">{{'textLengthWarning' | translate: {value: '18000'} }}</f-facet>
          <f-facet name="error" *ngIf="submitted && g.disputeDescription.errors?.wordCount">{{'ValidateWord' | translate}}</f-facet>
        </w-input-textarea>
      </w-slot>
    </w-section>

    <w-section>
      <f-facet name="header">{{ 'otherDocIfAny' | translate }}</f-facet>

      <w-slot>
        <w-input-file label="{{'annexTitle' | translate}}" [isLarge]="false" [auto]="false" [multiple]="false"
          [sizeLimit]="10000000" [labelSelect]="'addDocument' | translate"
          [invalidFileSizeMessageSummary]="'ValidFileSize' | translate"
          [showUploadButton]="false" [auto]="false" (wSelected)="onFileChange($event)" formControlName="otherDocuments">
        </w-input-file>
      </w-slot>
    </w-section>

    <w-section>
      <w-slot>
        <w-input-text label="{{'submittingIndividual' | translate}}" [labelOnTop]="true" [required]="true"
          formControlName="signature">
          <f-facet name="error" *ngIf="submitted && g.signature.errors?.required">{{'validationWarning' | translate }}</f-facet>
          <f-facet name="error" *ngIf="submitted && g.signature.errors?.maxlength">{{'textLengthWarning' | translate: {value: '255'} }}</f-facet>
        </w-input-text>
      </w-slot>
    </w-section>
  </w-edit-panel>

  <w-view [center]="false" [textPosition]="'start'">
    <f-facet name="description">
      <re-captcha formControlName="g_recaptcha_response" siteKey="{{siteKey}}"></re-captcha>
    </f-facet>
    <f-facet name="footer" *ngIf="submitted && g.g_recaptcha_response.errors?.required">
      <w-validation-panel [type]="'error'" [summary]="'validationCaptch' | translate"></w-validation-panel>
    </f-facet>
  </w-view>
  
</form>

<f-facet name="step-info">
<w-page-header [disableUppercase]="true" [showPortfolio]="false">
  <f-facet name="actions">
    <button w-button [isDefault]="true" [isSecondary]="false" [disabled]="false" [buttonType]="'text'" 
      type="submit" (click)="onSubmit()">
      {{ 'submitRequest' | translate }}
    </button>
  </f-facet>
</w-page-header>
<br><br>
</f-facet>

<w-dialog *ngIf="isOpen" [style]="style">
  <w-step title="{{'ipportalDefault.requestForGoodOffices.alerTitle' | translate}}" [reviewMode]="true">
    <div facet-step-info [innerHTML]="'ipportalDefault.requestForGoodOffices.alerText' | translate"></div>
    <button w-button (click)="closeDialogbox()" facet-step-actions>OK</button>
  </w-step>
</w-dialog>