import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Party, PartyType } from 'src/app/_models/party';
import { UploadService } from 'src/app/_services/upload.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/_shared/UtilsService';
import { AdditionalPartyComponent } from 'src/app/_shared/component/additional-party/additional-party.component';



@Component({
  selector: 'app-expedited-arbitration',
  templateUrl: './expedited-arbitration.component.html',
  styleUrls: ['./expedited-arbitration.component.scss']
})
export class ExpeditedArbitrationComponent {
  @ViewChild('additionalPartyForm', {static: false}) private additionalPartyForm: AdditionalPartyComponent;
  siteKey = environment.recaptchaSiteKey;

  requestingParty: Party;
  respondingParty: Party;
  additionalParties: Party[] = [];
  editedParty = new Party();
  currentLanguage = 'en';
  editAdd = false;
  submitted = false;
  blocked = false;
  isOpen = false;
  agreementFilename: string;
  choiceOfLawFilename: string;
  claimFilename: string;
  paymentFilename: string;
  attachmentFilename: string;
  invalidEmails = false;


  requestForm = this.fb.group({
    nameComplainant: ['', [Validators.required, Validators.maxLength(255)]],
    addressComplainant: ['', [Validators.required, Validators.maxLength(255)]],
    telephoneComplainant: ['', Validators.maxLength(255)],
    emailComplainant: ['', [Validators.required, this.utilsService.validateEmail, Validators.maxLength(255)]],
    nameRespondent: ['', [Validators.required, Validators.maxLength(255)]],
    addressRespondent: ['', [Validators.required, Validators.maxLength(255)]],
    telephoneRespondent: ['', Validators.maxLength(255)],
    emailRespondent: ['', [Validators.required, this.utilsService.validateEmail, Validators.maxLength(255)]],
    representedByRespondent: ['', Validators.maxLength(255)],
    addressRepresentativeRespondent: ['', Validators.maxLength(255)],
    telephoneRepresentativeRespondent: ['', Validators.maxLength(255)],
    emailRepresentativeRespondent: ['', [this.utilsService.validateEmail, Validators.maxLength(255)]],
    representedByComplainant: ['', Validators.maxLength(255)],
    addressRepresentativeComplainant: ['', Validators.maxLength(255)],
    telephoneRepresentativeComplainant: ['', Validators.maxLength(255)],
    emailRepresentativeComplainant: ['', [this.utilsService.validateEmail, Validators.maxLength(255)]]
  });

  requestOtherForm = this.fb.group({
    disputeDescription: ['', [Validators.required, this.utilsService.customWordCountValidator, Validators.maxLength(18000)]],
    claims: ['', [Validators.required, Validators.maxLength(1000)]],
    observations: ['', [Validators.required, Validators.maxLength(1000)]],
    signature: ['', [Validators.required, Validators.maxLength(255)]],
    agreement: ['', Validators.required],
    choiceOfLaw: null,
    claim: ['', Validators.required],
    payment: null,
    attachments: null,
    g_recaptcha_response: [null, Validators.required]
  });

 

  constructor(private cdr: ChangeDetectorRef, private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private uploadService: UploadService,
    private translate: TranslateService) { }


  onAttachmentChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.attachmentFilename = ': ' + file.name;
      this.requestOtherForm.get('attachments').setValue(file);
    }
  }

  onClaimChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.claimFilename = ': ' + file.name;
      this.requestOtherForm.get('claim').setValue(file);
    }
  }

  onChoiceOfLawChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.choiceOfLawFilename = ': ' + file.name;
      this.requestOtherForm.get('choiceOfLaw').setValue(file);
    }
  }

  onAgreementChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.agreementFilename = ': ' + file.name;
      this.requestOtherForm.get('agreement').setValue(file);
    }
  }

  onPaymentChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.paymentFilename = ': ' + file.name;
      this.requestOtherForm.get('payment').setValue(file);
    }
  }

  get f() { return this.requestForm.controls; }

  get g() { return this.requestOtherForm.controls; }

  onSubmit() {
    this.submitted = true;
    if(!this.additionalPartyForm.validate()) { return; }
    if (this.requestForm.invalid || this.requestOtherForm.invalid) {  return;   }
    this.blocked = true;
    const myFormData = new FormData();
    Object.keys(this.requestForm.controls).forEach((key) => {
      if (this.requestForm.value[key] === null) {
        myFormData.append(key, '');
      } else {
        myFormData.append(key, this.requestForm.value[key]);
      }
    });

    Object.keys(this.requestOtherForm.controls).forEach((key) => {
      if (this.requestForm.value[key] === null) {
        myFormData.append(key, '');
      } else {
        myFormData.append(key, this.requestOtherForm.value[key]);
      }
    });

    console.log('Lang  in mediation form ----------------', this.translate.currentLang);
    if (this.translate.currentLang) {
      this.currentLanguage = this.translate.currentLang
    } else {
      this.currentLanguage = 'en'
    }
    myFormData.append('language', this.currentLanguage);
    myFormData.append('additionalParties', JSON.stringify(this.additionalParties));

    this.uploadService.submitExpeditedArbitrationForm(myFormData).subscribe(
      response => {
        if (response instanceof HttpResponse) {
          if (response.status === 201) {
            this.blocked = false;
            this.isOpen = true;
          } else {
            this.blocked = false;
          }
        }
      }, err => {
        //  alert("Error caught at Subscriber " + err)
        this.blocked = false;
      }, () => { this.blocked = false; });
  }

  removeEle() {
    let elements = document.getElementsByClassName('b-input__multi-select--badge ng-star-inserted');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  closeDialogbox() {
    this.isOpen = false;
    this.submitted = false;
    this.removeEle();
    this.additionalParties = [];
    this.requestForm.reset();
    this.requestOtherForm.reset();
  }
}


