import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import decision_tree from "../../_files/decision_tree.json";

@Component({
  selector: 'wipo-mcst-dispute-related-contract',
  templateUrl: './wipo-mcst-dispute-related-contract.html'
})
export class WipoMcstIncludeDisputeRelatedContractComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private router: Router) { }

  ngOnInit(): void {
  }
  disputeRelatedContractSubmit = false;
  disputeRelatedContractSubmitBtn = true;
  isDisabledDisputeContract = false;
  resetBackButton = false;
  linkWipoMediactionClauseContract = false;
  linkNoWipoMediactionClauseContract = false;

  @Input() disputeRelatedContractFormStatus;
  @Output() disputeContractBackBtnEvent = new EventEmitter<boolean>();
  disputeRelatedContractOptions = decision_tree.disputeRelatedContractOptions;

  disputeRelatedContractForm = this.fb.group({
    disputeContract: ['', Validators.required]
  });

  get f() {
    return this.disputeRelatedContractForm.controls;
  }

  onSubmit() {
    this.disputeRelatedContractSubmit = true;
    if (this.disputeRelatedContractForm.invalid) {
      return;
    }
    else {
      this.disputeRelatedContractSubmitBtn = !this.disputeRelatedContractSubmitBtn;
      this.isDisabledDisputeContract = !this.isDisabledDisputeContract;
      if (this.disputeRelatedContractForm.controls.disputeContract.value === "wipoMediationClausesContract") {
        this.linkWipoMediactionClauseContract = !this.linkWipoMediactionClauseContract;
      }
      if (this.disputeRelatedContractForm.controls.disputeContract.value === "noWipoMediationClausesContract") {
        this.linkNoWipoMediactionClauseContract = !this.linkNoWipoMediactionClauseContract;
      }
    }
  }

  wipoDisputeContractPrevBtn() {
    this.resetBackButton = !this.resetBackButton;
    this.disputeContractBackBtnEvent.emit(this.resetBackButton);
  }

  wipoMediactionClauseContractBackBtn() {
    this.linkWipoMediactionClauseContract = !this.linkWipoMediactionClauseContract;
    this.disputeRelatedContractForm.reset();
    this.isDisabledDisputeContract = !this.isDisabledDisputeContract;
    this.disputeRelatedContractSubmitBtn = !this.disputeRelatedContractSubmitBtn;
    this.disputeRelatedContractSubmit = !this.disputeRelatedContractSubmit;
  }
  wipoNoMediactionClauseContractBackBtn() {
    this.linkNoWipoMediactionClauseContract = !this.linkNoWipoMediactionClauseContract;
    this.disputeRelatedContractForm.reset();
    this.isDisabledDisputeContract = !this.isDisabledDisputeContract;
    this.disputeRelatedContractSubmitBtn = !this.disputeRelatedContractSubmitBtn;
    this.disputeRelatedContractSubmit = !this.disputeRelatedContractSubmit;
  }
  wipoRequestMediationLinkNext(event) {
    this.router.navigate(['/adr/mediation'])
      .then(success => console.log('navigation success?', success))
      .catch(console.error);
  }

}
