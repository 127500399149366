import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { UploadService } from 'src/app/_services/upload.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { UtilsService } from 'src/app/_shared/UtilsService';

@Component({
  selector: 'app-adryoung-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})


export class AdrYoungRegistrationComponent {

  submitted = false;

  siteKey = environment.recaptchaSiteKey;
  blocked = false;
  isOpen = false;

  requestForm = this.fb.group({
    firstname: ['', [Validators.required, Validators.maxLength(255)]],
    lastname: ['', [Validators.required, Validators.maxLength(255)]],
    jobtitle: ['', [Validators.required, Validators.maxLength(255)]],
    organization: ['', [Validators.required, Validators.maxLength(255)]],
    dateofbirth: ['', [Validators.required, Validators.maxLength(255)]],
    city: ['', [Validators.required, Validators.maxLength(255)]],
    country: ['', [Validators.required, Validators.maxLength(255)]],
    emailc: ['', [Validators.required, this.utilsService.validateEmail, Validators.maxLength(255)]],
    onlineprofile: ['', Validators.maxLength(255)],
    how: ['', Validators.maxLength(255)],
    documents: null,
    g_recaptcha_response: [null, Validators.required]
  });

  AMC_LANGUAGE_COOKIE = 'amc_language'; // WIPO language standard cookie name
  filename: string;
  currentFile: File;
  countryOptions = [];
  temp: any[] = [];
  currentLanguage = this.cookieService.get(this.AMC_LANGUAGE_COOKIE);

  constructor(private cdr: ChangeDetectorRef, private utilsService: UtilsService,
    private fb: UntypedFormBuilder,
    private uploadService: UploadService, private translate: TranslateService,private cookieService: CookieService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang
      this.countryOptions = this.utilsService.loadCountryOption();
    });
    this.countryOptions = this.utilsService.loadCountryOption();
  }

  onFileChange(files) {
    if (files.length > 0) {
      let file = files[0];
      this.currentFile = file;
      this.filename = ': ' + file.name;
      this.requestForm.get('documents').setValue(file);
    }
  }

  get f() { return this.requestForm.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.requestForm.invalid) {
      return;
    }

    const myFormData = new FormData();
    Object.keys(this.requestForm.controls).forEach((key) => {
      myFormData.append(key, this.requestForm.value[key]);
    });

    myFormData.append('language', this.currentLanguage);

    this.uploadService.submitAdrYoungRegistrationForm(myFormData).subscribe(
      response => {
        if (response instanceof HttpResponse) {
          if (response.status === 201) {
            this.blocked = false;
            this.isOpen = true;
          } else {
            this.blocked = false;
          }
        }
      }, err => {
        //  alert("Error caught at Subscriber " + err)
        this.blocked = false;
      }, () => { this.blocked = false; });
  }


  removeEle() {
    let elements = document.getElementsByClassName('b-input__multi-select--badge ng-star-inserted');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  closeDialogbox() {
    this.isOpen = false;
    this.submitted = false;
    this.removeEle();
    this.requestForm.reset();
  }


}
