import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import decision_tree from '../../_files/mcst_decision_tree.json';

export interface treeInfo {
  selectLabel: string,
  selectedArea: boolean,
  selectOptions: Object[]
}

export interface linkInfo {
  SelectLink: boolean,
  selectTitleLabel: string,
  linkValue: string,
  selectButtonLabel: string,
  position: String,
}

@Component({
  selector: 'app-wipo-mcst-decision-tree',
  templateUrl: './wipo-mcst-decision-tree.component.html'
})
export class WipoMcstDecisionTreeComponent implements OnInit, OnDestroy {
  translateSubscription: Subscription;
  SITE_URL: string = environment.siteUrl + 'amc-forms/';
  URL: string = '';
  secondFormInformation: treeInfo = {
    selectLabel: '',
    selectedArea: false,
    selectOptions: []
  };

  thirdFormInformation: treeInfo = {
    selectLabel: '',
    selectedArea: false,
    selectOptions: []
  };

  forthFormInformation: treeInfo = {
    selectLabel: '',
    selectedArea: false,
    selectOptions: []
  };

  linkinfo: linkInfo = {
    SelectLink: false,
    selectTitleLabel: '',
    linkValue: '',
    selectButtonLabel: '',
    position: '',
  };

  selectThirdOptionValue: any;
  selectForthOptionValue: any;
  collaboration: any;
  selectedValueOpt = [];


  constructor(private router: Router, private fb: UntypedFormBuilder,
    private activatedroute: ActivatedRoute,
    private translate: TranslateService,
    private cdref: ChangeDetectorRef) { }

  selectedOptionValue: string = '';
  secondFormName = '';
  thirdFormName = '';
  forthFormName = '';

  firstForm = this.fb.group({
    selectFirstOptionValue: ['', Validators.required]
  });

  secondForm = this.fb.group({
    selectSecondOptionValue: ['', Validators.required]
  });

  involvedcopyrghtForm = this.fb.group({
    selectSecondOptionValue: ['', Validators.required]
  });

  informationcopyrghtForm= this.fb.group({
    selectSecondOptionValue: ['', Validators.required]
  });
  informationresolutionForm = this.fb.group({
    selectSecondOptionValue: ['', Validators.required]
  });

  contractanotherpartyForm = this.fb.group({
    selectThirdOptionValue: ['', Validators.required]
  });
  noncontractanotherpartyForm = this.fb.group({
    selectThirdOptionValue: ['', Validators.required]
  });
  nowipomediationForm = this.fb.group({
    selectForthOptionValue: ['', Validators.required]
  });

  selectedOptions = [];

  ngOnInit(): void {
    this.activatedroute.data.subscribe(data => {
      this.collaboration = data.collaboration;
    })
    this.translateSubscription = this.translate.onLangChange.subscribe(() => {
      console.log('Lang changed', this.translate.currentLang);
      this.reloadTranslations();
    });
  }

  ngOnDestroy() {
    if (this.translateSubscription) { this.translateSubscription.unsubscribe() }
  }


  selectFirstOption(form) {
    const selectedValue = form.value.selectFirstOptionValue;
    this.involvedcopyrghtForm.reset();
    this.selectedOptions = [];
    this.informationcopyrghtForm.reset();
    this.informationresolutionForm.reset();
    this.contractanotherpartyForm.reset();
    this.noncontractanotherpartyForm.reset();
    this.secondFormName = '';
    this.secondForm.reset();
    this.secondFormInformation.selectedArea = false;
    this.thirdFormInformation.selectedArea = false;
    this.forthFormInformation.selectedArea = false;
    this.secondFormInformation.selectOptions = [];
    this.linkinfo.SelectLink = false;
    this.commonShow(selectedValue);
    this.addItemToSelectedOptions(selectedValue, form, 'First');
  }

  selectSecondOption(form) {
    const selectedValue = form.value.selectSecondOptionValue;
    this.contractanotherpartyForm.reset();
    this.noncontractanotherpartyForm.reset();
    this.thirdFormInformation.selectedArea = false;
    this.forthFormInformation.selectedArea = false;
    this.linkinfo.SelectLink = false;
    this.commonShow(selectedValue);
    this.addItemToSelectedOptions(selectedValue, form, 'Second');
  }

  selectThirdOption(form) {
    const selectedValue = form.value.selectThirdOptionValue;
    this.selectThirdOptionValue = selectedValue;
    this.nowipomediationForm.reset();
    this.forthFormInformation.selectedArea = false;
    this.linkinfo.SelectLink = false;
    this.commonShow(selectedValue);
    this.addItemToSelectedOptions(selectedValue, form, 'Third');
  }

  selectForthOption(form) {
    const selectedValue = form.value.selectForthOptionValue;
    this.selectForthOptionValue = selectedValue;
    this.linkinfo.SelectLink = false;
    this.commonShow(selectedValue);
    this.addItemToSelectedOptions(selectedValue, form, 'Forth');
  }

 
  commonShow(selectedValue) {
    console.log('Select Value -------', selectedValue);
    if(selectedValue) {
    switch (selectedValue) {
      case 'informationresolution': {
        this.secondFormName = 'informationresolutionForm';
        this.secondFormInformation.selectLabel = this.translate.instant('mcstForm.tree.informationresolution.label');
        this.secondFormInformation.selectedArea = true;
        this.secondFormInformation.selectOptions = this.translate.instant('mcstForm.tree.informationresolution_options');
        break;
      } case 'nowipomediation': {

        console.log('-----------------nowipomediation-------------')
        this.forthFormName = 'nowipomediationForm';
        this.thirdFormInformation.selectedArea = true;
        this.forthFormInformation.selectLabel = this.translate.instant('mcstForm.tree.selectOneOption.label');
        this.forthFormInformation.selectedArea = true;
        this.forthFormInformation.selectOptions = this.translate.instant('mcstForm.tree.nowipomediation_options');
        break;
      } case 'wipomediation': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.filewipomeduationLink.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.filewipomeduationLink.fileRequestButton');
        this.linkinfo.linkValue = 'gofilewipomediationLink';
        this.linkinfo.position='left';
        break;
      } case 'informationcopyrght': { 
        this.secondFormName = 'informationcopyrghtForm';
        this.secondFormInformation.selectLabel = this.translate.instant('mcstForm.tree.selectOneOption.label');
        this.secondFormInformation.selectedArea = true;
        this.secondFormInformation.selectOptions = this.translate.instant('mcstForm.tree.informationcopyrght_options');
        break;
      } case 'involvedcopyrght': {
        this.secondFormName = 'involvedcopyrghtForm';
        this.secondFormInformation.selectLabel = this.translate.instant('mcstForm.tree.informationresolution.label');
        this.secondFormInformation.selectedArea = true;
        this.secondFormInformation.selectOptions = [];
        this.secondForm.reset();
        this.secondFormInformation.selectOptions = this.translate.instant('mcstForm.tree.involvedcopyrght_options');
        break;
      } case 'contractanotherparty': {
        this.thirdFormName = 'contractanotherpartyForm';
        this.thirdFormInformation.selectLabel = this.translate.instant('mcstForm.tree.contractanotherparty.label');
        this.thirdFormInformation.selectedArea = true;
        this.thirdFormInformation.selectOptions = this.translate.instant('mcstForm.tree.contractanotherparty_options');
        break;
      } case 'noncontractanotherparty': {
        this.thirdFormName = 'noncontractanotherpartyForm';
        this.thirdFormInformation.selectLabel = this.translate.instant('mcstForm.tree.selectOneOption.label');
        this.thirdFormInformation.selectedArea = true;
        this.thirdFormInformation.selectOptions = this.translate.instant('mcstForm.tree.noncontractanotherparty_options');
        break;
      } case 'informationResourceMeditation': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.informationResourceMeditation.linkLabel');
        this.linkinfo.selectButtonLabel = '';
        this.linkinfo.linkValue = 'goinformationResourceMeditation';
        break;
      } case 'disputeResolutionContract': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.disputeResolutionContract.linkLabel');
        this.linkinfo.selectButtonLabel = '';
        this.linkinfo.linkValue = 'godisputeResolutionContract';
        break;
      } case 'contactByEmail': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.contactByEmail.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.contactByEmail.buttonLabel');
        this.linkinfo.linkValue = 'goConatctEmail';
        break;
      } case 'contactByTelephone': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.contactByTelephone.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.contactByTelephone.buttonLabel');
        this.linkinfo.linkValue = 'goConatctTelephone';
        break;
      } case 'contactBysocialmediachannels': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.contactBysocialmediachannels.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.contactBysocialmediachannels.buttonLabel');
        this.linkinfo.linkValue = 'goSocialMediaLink';
        break;
      } case 'filewipomeduationLink': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.filewipomeduationLink.linkLabel');
        this.linkinfo.selectButtonLabel = '';
        this.linkinfo.linkValue = 'gofilewipomeduationLink';
        break;
      } case 'inviteotherpartywipomediationLink': {
        this.thirdFormInformation.selectedArea = true;
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.inviteotherpartywipomediationLink.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.inviteotherpartywipomediationLink.fileRequestButton');// here
        this.linkinfo.linkValue = 'goinviteotherpartywipomediationLink';
        this.linkinfo.position = 'left';
        break;
      } case 'contractwipoADRLink': {
        this.thirdFormInformation.selectedArea = true;
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.wipoADRLink.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.wipoADRLink.fileRequestButton');
        this.linkinfo.linkValue = 'gowipoADR';
        this.linkinfo.position = 'left';
        break;
      } case 'wipoADRLink': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.wipoADRLink.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.wipoADRLink.fileRequestButton');
        this.linkinfo.linkValue = 'gowipoADR';
        this.linkinfo.position = 'left';
        break;
      } case 'contactwipoarbitrationandmediationLink': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.contactwipoarbitrationandmediationLink.linkLabel');
        this.linkinfo.selectButtonLabel = '';
        this.linkinfo.linkValue = 'gowipoADR';
        break;
      } case 'wipoMediationLink': {
        this.linkinfo.SelectLink = true;
        this.linkinfo.selectTitleLabel = this.translate.instant('mcstForm.tree.wipoMediationLink.linkLabel');
        this.linkinfo.selectButtonLabel = this.translate.instant('mcstForm.tree.wipoMediationLink.fileRequestButton');
        this.linkinfo.linkValue = 'gowipoMediationLink';
        this.linkinfo.position = 'left';
        break;
      } default: {
        this.linkinfo.SelectLink = false;
        this.thirdFormInformation.selectedArea = false;
        break;
      }

    }
  }
    this.cdref.detectChanges();
  }


  showLink(getLink) {

    switch (getLink) {
      case 'gowipoADR': {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/good-offices-services';
        else
        this.URL = this.SITE_URL + 'adr/good-offices-services';
        break;
      } case 'goConatctEmail': {
        this.URL = 'https://www3.wipo.int/contact/en/area.jsp?area=arbitration';
        break;
      } case 'goConatctTelephone': {
        this.URL = 'https://www3.wipo.int/contact/en/area.jsp?area=arbitration';
        break;
      } case 'goSocialMediaLink': {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/good-offices-services';
        else
        this.URL = this.SITE_URL + 'adr/good-offices-services';
        break;
      } case 'godisputeResolutionContract': {
        if(this.collaboration)
        this.URL = 'https://www.wipo.int/amc/ko/clauses/index.html';
        else
        this.URL = 'https://www.wipo.int/amc/en/clauses/index.html';
        break;
      } case 'gowipoMediationLink': {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/unilateral-mediation';
        else
        this.URL = this.SITE_URL + 'adr/unilateral-mediation';
        break;
      } case 'gofilewipomeduationLink': {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/unilateral-mediation';
        else
        this.URL = this.SITE_URL + 'adr/unilateral-mediation';
        break;
      } case 'goinviteotherpartywipomediationLink': {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/unilateral-mediation';
        else
        this.URL = this.SITE_URL + 'adr/unilateral-mediation';
        break;
      } case 'gofilewipomediationLink' : {
        if(this.collaboration)
        this.URL = this.SITE_URL + 'rok/mcst/mediation';
        else
        this.URL = this.SITE_URL + 'adr/mediation';
        break;
      }
      default: {
        this.URL = this.SITE_URL;
        break;
      }

    }

    window.open(this.URL, '_blank');

  }

  /**
   * add Item to the selected options
   *
   * @memberof WipoMcstDecisionTreeComponent
   */
  addItemToSelectedOptions(selectedValue, form, level): void{
    const existinIndex = this.selectedOptions.findIndex(item => item.level === level);
    const selectedOption = {level, value: selectedValue, form};
    if (existinIndex !== -1) {
      this.selectedOptions[existinIndex] = selectedOption;
    } else {
      this.selectedOptions = [...this.selectedOptions, selectedOption];
    }
  }

  /**
   * Refresh translations
   *
   * @memberof WipoMcstDecisionTreeComponent
   */
  reloadTranslations(): void {
    if (this.selectedOptions.length) {
      this.selectedOptions.forEach((item) => {
        const { form } = item;
        if (form) {
          form.get(`select${item.level}OptionValue`).setValue(item.value);
          this[`select${item.level}Option`](form);
        }
      });
    }
  }


}