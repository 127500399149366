import { Injectable } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthService, OAuthSuccessEvent, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WipoAuthService {
  refreshTimeout = environment.refreshTimeout;
  private defaultLanguage = 'en';
  private loginUsername = '';
  private loginUserEmail = '';
  private userProfile: UserInfo;
  private authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.oidcIssuer,
    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.oidcClientId,
    // URL of the SPA to redirect the user to after login
    redirectUri: environment.oidcRedirectUri,
    silentRefreshRedirectUri: environment.oidcSilentRedirectUri,
    postLogoutRedirectUri: environment.oidcPostLogoutRedirectUri,
    scope: environment.oidcScope
  };

  private authenticated = false;

  private _loginChangedSubject = new BehaviorSubject<boolean>(false);
  private _login2FaChangedSubject = new BehaviorSubject<boolean>(false);
  authStatusObservable = this._loginChangedSubject;
  login2FaChanged = this._login2FaChangedSubject;

  constructor(
    private oauthService: OAuthService,
    _cookieService: CookieService,
    private router: Router,
  ) {
    // override silent refresh frequency
    if (_cookieService.get('refresh-timeout')) {
      this.refreshTimeout = +_cookieService.get('refresh-timeout');
    }
  }
  silentRefresh(): void {
    this.oauthService.silentRefresh().then(event => {
      console.log('wipoAuthService: SILENT REFRESH', event, 'expire:', new Date(this.oauthService.getAccessTokenExpiration()));
    }, (e) => {
      console.error('wipoAuthService: silent refresh failed', e);
    });
  }

  private async setAuthenticatedMode(loa: string): Promise<void> {
    // this.userProfile = await this.oauthService.loadUserProfile();

    // if (!this.userProfile) {
    //   try {

    //     this.userProfile = await this.oauthService.loadUserProfile();
    //     console.log('wipoAuthService: get user profile from AM',this.userProfile);
    //     // this.loginUserEmail =  this.userProfile.email,
    //     // this.loginUsername =  this.userProfile.given_name +' '+ this.userProfile.family_name;
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    console.log('wipoAuthService: set authenticated mode, loa:', loa);
    // this.loginUsername = username;
    this.authenticated = true;
    this._loginChangedSubject.next(true);
    this._login2FaChangedSubject.next(loa === '3');
  }

  public get username() {
    if (!this.authenticated) {
      return '';
    } else {
      return this.loginUsername;
    }
  }


  public get preferedLangague() {

    if (!this.authenticated) {
      return '';
    } else {
      return this.defaultLanguage;
    }
  }

  public get useremail() {
    if (!this.authenticated) {
      return '';
    } else {
      // this.loginUserEmail =  this.userProfile.email;
      return this.loginUserEmail;
    }
  }

  private setUnauthenticatedMode(): void {
    console.log('wipoAuthService: set unauthenticated mode');
    // this.loginUsername = '';
    this.authenticated = false;
    this._loginChangedSubject.next(false);
    this._login2FaChangedSubject.next(false);
  }

  logout(): void {
    console.log('wipoAuthService: logout()');
    this.oauthService.logOut();
  }

  loginWithUserPassword(state?: string): void {
    console.log('wipoAuthService: loginWithUserPassword(), url:', state);
    this.oauthService.customQueryParams = { loa: '2' }; // 2 = basic authentication, user and password
    this.oauthService.initImplicitFlow(state);
  }

  loginWith2FA(state?: string): void {
    console.log('wipoAuthService: loginWith2FA()');
    this.oauthService.customQueryParams = { loa: '3' }; // 3 = 2FA
    this.oauthService.initImplicitFlow(state);
  }

  isUserAuthenticated(): boolean {
    return this.authenticated;
  }

  // entry point
  initialize(): Promise<void> {
    return new Promise(async (resolve) => {
      // init
      this.watchSilentRefresh();
      this.oauthService.configure(this.authConfig);
      this.oauthService.timeoutFactor = this.refreshTimeout;
      console.log('wipoAuthService: refresh period:', this.refreshTimeout);
      this.oauthService.setupAutomaticSilentRefresh();
      // try login
      const status = await this.oauthService.loadDiscoveryDocumentAndTryLogin().catch(error => console.error(error));
      console.log('wipoAuthService: logged-in:', status, `state: ${this.oauthService.state}`);
      console.log('this.isUserAuthenticated ---- ',this.isUserAuthenticated());
      console.log('this.authenticated----',this.authenticated);
      try {
        console.log('status ----',status)
        if (status) {
          console.log('wipoAuthService: Access token expire:', new Date(this.oauthService.getAccessTokenExpiration()));
          this.refreshIdentityClaimsVariables();
          this.setAuthenticatedMode('2');
          if (this.oauthService.state) {
            console.log('wipoAuthService: SUCCESS, REDIRECT:', this.oauthService.state);
            console.log(window.location.origin + '/amc-forms' + this.oauthService.state);

            this.router.navigateByUrl(this.oauthService.state);
            // this.router.navigate([this.oauthService.state]);
          }
        } else {
          console.log('wipoAuthService: User not authenticated');
          this.setUnauthenticatedMode();
        }
      } catch (error) {
        console.error('wipoAuthService: failed to get profile', error);
        this.setUnauthenticatedMode();
      }
      
      console.log('wipoAuthService: LOADED');
      resolve();
    });
  }

  private refreshIdentityClaimsVariables() {
    console.log('wipoAuthService: refreshIdentityClaimsVariables()');
    const claims = this.oauthService.getIdentityClaims();
    console.log('wipoAuthService: claims=', claims);
    console.log('-------- Test for changes-----------');
    if (claims) {
      this.loginUsername = claims['given_name'] + ' ' + claims['family_name'];
      this.loginUserEmail = claims['email'];
      this.defaultLanguage = claims['locale'];
      
    }
  }

  private watchSilentRefresh(): void {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('wipoAuthService: OAuthErrorEvent:', event);
        if (event.type === 'silent_refresh_error') {
          console.warn('wipoAuthService: SILENT REFRESH FAILED @', this.router.url);
          this.setUnauthenticatedMode();
          this.loginWithUserPassword(this.router.url);
        }
      } else if (event instanceof OAuthSuccessEvent && event.type === 'silently_refreshed') {
        console.log('wipoAuthService: OAuthEvent Access token REFRESHED, valid until:',
          new Date(this.oauthService.getAccessTokenExpiration()));
      }
    });
  }
}