import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import {  OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly _authService: OAuthService,
    private readonly _router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const accessToken = this._authService.getAccessToken();
    const authReq = req.clone()
    authReq.headers.set("Authorization",accessToken)
    authReq.headers.append("new-id", "12345")

    return next.handle(authReq).pipe(
      tap(
        () => { },
        error => {
          const respError = error as HttpErrorResponse;
          if ( respError && (respError.status === 401 || respError.status === 403) ) {
            debugger;
            this._router.navigate(['/page-not-found']);
          }
        }
      )
    );
  }
}