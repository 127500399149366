import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
export interface treeInfo {
  selectLabel: string,
  selectedArea: boolean,
  selectOptions: Object[]
}

@Component({
  selector: 'tree-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

  @Output() selectVal: EventEmitter<string> = new EventEmitter();
  @Output() ThirdOptionValue: EventEmitter<string> = new EventEmitter();

  @Input() options;
  

 thirdFormInformation: treeInfo = {
  selectLabel: '',
  selectedArea: false,
  selectOptions: []
};

  constructor( private translate: TranslateService,private fb: UntypedFormBuilder) { }
  thirdForm = this.fb.group({
    selectThirdOptionValue: ['', Validators.required]
  });
  ngOnInit(): void {
    this.thirdFormInformation.selectLabel = this.translate.instant('mcstForm.tree.contractanotherparty.label');
  }

  soption(selectedValue) {
    
  if(selectedValue){
    this.thirdForm.value.selectThirdOptionValue = selectedValue;
      this.selectVal.next(selectedValue);
  }
  }

     
    

  
}
