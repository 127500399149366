<form [formGroup]="thirdForm" >
    <w-edit-panel>
      <w-section>
        <w-slot>
          <w-input-radio [options]="options" 
          label="{{thirdFormInformation.selectLabel}}" 
          formControlName="selectThirdOptionValue"
          (click)="soption(this.thirdForm.value.selectThirdOptionValue)" 
          (change)="soption(this.thirdForm.value.selectThirdOptionValue)" >
          </w-input-radio>
        </w-slot>
      </w-section>
    </w-edit-panel>
  </form>



  
