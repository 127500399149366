import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LoaderService } from '../_services/loader.service';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let result = request.url.includes("/private/"); // Check this request come forgit push indautor page
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // We initialize the error code to a generic 500 in case the error is not controlled by the API
          let errorResponse = {errorCode: 500};
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.error.errorCode}\nMessage: ${error.error.message}`;
            errorResponse = JSON.parse(error.error);
          }
          //window.alert(errorMessage);
          if (result) {
            this.loaderService.load(errorResponse.errorCode)
          }else {
            // Only open error box except indautor page
            this.loaderService.show(errorResponse.errorCode);
            return throwError(errorMessage);
          }
        })
      )
  }
}